@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';
@import 'mixins';

.container {
  @include question-container;

  padding: 0 16px;
}

.inner-container {
  margin: 0 auto !important;
}

.button {
  @include p-size-mobile {
    margin-bottom: 80px;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-left: -16px;
}

.card-height {
  height: 268px;
}

.info-description {
  height: 96px;

  @include p-size-mobile {
    height: fit-content;
  }
}

.segmentedContainer {
  position: relative;

  display: inline-flex;

  background-color: $ds-purple-100;
  height: 48px;

  border-radius: 8px;
}

.selectedBackground {
  position: absolute;

  z-index: 0;

  top: 8px;

  width: 102px;
  height: 32px;

  background-color: white;

  box-shadow: 0px 1px 8px rgba(38, 38, 46, 0.1);

  border-radius: 8px;

  &--position-0 {
    left: 8px;
  }

  &--position-1 {
    left: 118px;
  }

  transition: left 0.15s;
  transition-timing-function: ease-in-out;
}

.item {
  cursor: pointer;

  z-index: 1;

  min-width: 114px;
  text-align: center;
  height: 48px;
  line-height: 48px;

  font-weight: bold;

  color: $ds-purple-500;

  &:hover {
    color: $ds-purple-700;
  }

  &--selected {
    color: $ds-grey-900;

    &:hover {
      color: $ds-grey-900;
    }
  }
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.headerImgWrapper {
  position: relative;
  width: 100%;
  height: 72px;

  @include p-size-tablet {
    height: 132px;
    padding-top: 72px;
    margin-bottom: 40px;
  }
}

.headerImg {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 72px;
  border-radius: 8px;

  @include p-size-tablet {
    height: 112px;
    top: 72px;
  }
}

.titleContainer {
  margin-top: 64px;

  @include p-size-tablet {
    margin-top: 76px;
  }
}

.toothReplacementContainer {
  @include p-size-tablet {
    margin-bottom: 24px;
  }
}

.reviewContainer {
  padding: 40px 0;

  @include p-size-tablet {
    padding: 88px 0;
  }
}

.fixedPriceBar {
  position: fixed;
  height: 96px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: $ds-grey-100;
  border-top: 1px solid $ds-primary-100;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.mobileSticky {
  display: flex;

  @include p-size-tablet {
    display: none;
  }
}

.desktopSticky {
  display: none;

  @include p-size-tablet {
    display: flex;
  }
}

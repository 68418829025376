@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/font-weight';

.question-container {
  border-bottom: 1px solid $ds-grey-200;
}

.chevron-animation {
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
}

.markdown {
  & strong {
    font-weight: $p-font-weight-bold;
  }

  & li {
    margin-left: 32px;
  }
}

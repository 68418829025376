@import '@popsure/dirty-swan/dist/grid';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-header {
  @include p-size-mobile {
    margin-top: 16px !important;
  }
}

.icon {
  width: 144px;
  height: 144px;

  @include p-size-mobile {
    width: 104px;
    height: 104px;

    margin-top: 40px !important;
  }
}

.button {
  @include p-size-mobile {
    margin-bottom: 80px;
  }
}

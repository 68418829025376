@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  margin-top: 48px;

  @include p-size-desktop {
    margin-top: 80px;
  }
}

.header {
  height: 48px;
}

.empty-container {
  height: 440px;
}

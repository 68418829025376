@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.description {
  margin-top: -4px;
}

.icon{
  align-items: start;

  img {
    width: 24px;
    height: 24px;

    @include p-size-desktop {
      width: 32px;
      height: 32px;
    }
  }
}
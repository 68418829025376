@import '@popsure/dirty-swan/dist/colors';

$url: 'https://assets.cdn.feather-insurance.com/assets/images/flags/';

.react-tel-input {
  font-family: Lato, sans-serif;
  font-size: 16px;
  position: relative;
  width: 100%;

  :disabled {
    cursor: not-allowed;
  }

  .flag {
    width: 32px;
    height: 24px;

    background-color: $ds-grey-200;
    background-size: contain;
    border-radius: 2px;
  }

  .ad {
    background-image: url('#{$url}ad.png');
  }
  .ae {
    background-image: url('#{$url}ae.png');
  }
  .af {
    background-image: url('#{$url}af.png');
  }
  .ag {
    background-image: url('#{$url}ag.png');
  }
  .ai {
    background-image: url('#{$url}ai.png');
  }
  .al {
    background-image: url('#{$url}al.png');
  }
  .am {
    background-image: url('#{$url}am.png');
  }
  .ao {
    background-image: url('#{$url}ao.png');
  }
  .aq {
    background-image: url('#{$url}aq.png');
  }
  .ar {
    background-image: url('#{$url}ar.png');
  }
  .as {
    background-image: url('#{$url}as.png');
  }
  .at {
    background-image: url('#{$url}at.png');
  }
  .au {
    background-image: url('#{$url}au.png');
  }
  .aw {
    background-image: url('#{$url}aw.png');
  }
  .ax {
    background-image: url('#{$url}ax.png');
  }
  .az {
    background-image: url('#{$url}az.png');
  }
  .ba {
    background-image: url('#{$url}ba.png');
  }
  .bb {
    background-image: url('#{$url}bb.png');
  }
  .bd {
    background-image: url('#{$url}bd.png');
  }
  .be {
    background-image: url('#{$url}be.png');
  }
  .bf {
    background-image: url('#{$url}bf.png');
  }
  .bg {
    background-image: url('#{$url}bg.png');
  }
  .bh {
    background-image: url('#{$url}bh.png');
  }
  .bi {
    background-image: url('#{$url}bi.png');
  }
  .bj {
    background-image: url('#{$url}bj.png');
  }
  .bl {
    background-image: url('#{$url}bl.png');
  }
  .bm {
    background-image: url('#{$url}bm.png');
  }
  .bn {
    background-image: url('#{$url}bn.png');
  }
  .bo {
    background-image: url('#{$url}bo.png');
  }
  .bq {
    background-image: url('#{$url}bq.png');
  }
  .br {
    background-image: url('#{$url}br.png');
  }
  .bs {
    background-image: url('#{$url}bs.png');
  }
  .bt {
    background-image: url('#{$url}bt.png');
  }
  .bv {
    background-image: url('#{$url}bv.png');
  }
  .bw {
    background-image: url('#{$url}bw.png');
  }
  .by {
    background-image: url('#{$url}by.png');
  }
  .bz {
    background-image: url('#{$url}bz.png');
  }
  .ca {
    background-image: url('#{$url}ca.png');
  }
  .cc {
    background-image: url('#{$url}cc.png');
  }
  .cd {
    background-image: url('#{$url}cd.png');
  }
  .cf {
    background-image: url('#{$url}cf.png');
  }
  .cg {
    background-image: url('#{$url}cg.png');
  }
  .ch {
    background-image: url('#{$url}ch.png');
  }
  .ci {
    background-image: url('#{$url}ci.png');
  }
  .ck {
    background-image: url('#{$url}ck.png');
  }
  .cl {
    background-image: url('#{$url}cl.png');
  }
  .cm {
    background-image: url('#{$url}cm.png');
  }
  .cn {
    background-image: url('#{$url}cn.png');
  }
  .co {
    background-image: url('#{$url}co.png');
  }
  .cr {
    background-image: url('#{$url}cr.png');
  }
  .cu {
    background-image: url('#{$url}cu.png');
  }
  .cv {
    background-image: url('#{$url}cv.png');
  }
  .cw {
    background-image: url('#{$url}cw.png');
  }
  .cx {
    background-image: url('#{$url}cx.png');
  }
  .cy {
    background-image: url('#{$url}cy.png');
  }
  .cz {
    background-image: url('#{$url}cz.png');
  }
  .de {
    background-image: url('#{$url}de.png');
  }
  .dj {
    background-image: url('#{$url}dj.png');
  }
  .dk {
    background-image: url('#{$url}dk.png');
  }
  .dm {
    background-image: url('#{$url}dm.png');
  }
  .do {
    background-image: url('#{$url}do.png');
  }
  .dz {
    background-image: url('#{$url}dz.png');
  }
  .ec {
    background-image: url('#{$url}ec.png');
  }
  .ee {
    background-image: url('#{$url}ee.png');
  }
  .eg {
    background-image: url('#{$url}eg.png');
  }
  .eh {
    background-image: url('#{$url}eh.png');
  }
  .er {
    background-image: url('#{$url}er.png');
  }
  .es-ca {
    background-image: url('#{$url}es-ca.png');
  }
  .es-ga {
    background-image: url('#{$url}es-ga.png');
  }
  .es {
    background-image: url('#{$url}es.png');
  }
  .et {
    background-image: url('#{$url}et.png');
  }
  .eu {
    background-image: url('#{$url}eu.png');
  }
  .fi {
    background-image: url('#{$url}fi.png');
  }
  .fj {
    background-image: url('#{$url}fj.png');
  }
  .fk {
    background-image: url('#{$url}fk.png');
  }
  .fm {
    background-image: url('#{$url}fm.png');
  }
  .fo {
    background-image: url('#{$url}fo.png');
  }
  .fr {
    background-image: url('#{$url}fr.png');
  }
  .ga {
    background-image: url('#{$url}ga.png');
  }
  .gb-eng {
    background-image: url('#{$url}gb-eng.png');
  }
  .gb-nir {
    background-image: url('#{$url}gb-nir.png');
  }
  .gb-sct {
    background-image: url('#{$url}gb-sct.png');
  }
  .gb-wls {
    background-image: url('#{$url}gb-wls.png');
  }
  .gb {
    background-image: url('#{$url}gb.png');
  }
  .gd {
    background-image: url('#{$url}gd.png');
  }
  .ge {
    background-image: url('#{$url}ge.png');
  }
  .gf {
    background-image: url('#{$url}fr.png');
  }
  .gg {
    background-image: url('#{$url}gg.png');
  }
  .gh {
    background-image: url('#{$url}gh.png');
  }
  .gi {
    background-image: url('#{$url}gi.png');
  }
  .gl {
    background-image: url('#{$url}gl.png');
  }
  .gm {
    background-image: url('#{$url}gm.png');
  }
  .gn {
    background-image: url('#{$url}gn.png');
  }
  .gp {
    background-image: url('#{$url}fr.png');
  }
  .gq {
    background-image: url('#{$url}gq.png');
  }
  .gr {
    background-image: url('#{$url}gr.png');
  }
  .gs {
    background-image: url('#{$url}gs.png');
  }
  .gt {
    background-image: url('#{$url}gt.png');
  }
  .gu {
    background-image: url('#{$url}gu.png');
  }
  .gw {
    background-image: url('#{$url}gw.png');
  }
  .gy {
    background-image: url('#{$url}gy.png');
  }
  .hk {
    background-image: url('#{$url}hk.png');
  }
  .hm {
    background-image: url('#{$url}hm.png');
  }
  .hn {
    background-image: url('#{$url}hn.png');
  }
  .hr {
    background-image: url('#{$url}hr.png');
  }
  .ht {
    background-image: url('#{$url}ht.png');
  }
  .hu {
    background-image: url('#{$url}hu.png');
  }
  .id {
    background-image: url('#{$url}id.png');
  }
  .ie {
    background-image: url('#{$url}ie.png');
  }
  .il {
    background-image: url('#{$url}il.png');
  }
  .im {
    background-image: url('#{$url}im.png');
  }
  .in {
    background-image: url('#{$url}in.png');
  }
  .io {
    background-image: url('#{$url}io.png');
  }
  .iq {
    background-image: url('#{$url}iq.png');
  }
  .ir {
    background-image: url('#{$url}ir.png');
  }
  .is {
    background-image: url('#{$url}is.png');
  }
  .it {
    background-image: url('#{$url}it.png');
  }
  .je {
    background-image: url('#{$url}je.png');
  }
  .jm {
    background-image: url('#{$url}jm.png');
  }
  .jo {
    background-image: url('#{$url}jo.png');
  }
  .jp {
    background-image: url('#{$url}jp.png');
  }
  .ke {
    background-image: url('#{$url}ke.png');
  }
  .kg {
    background-image: url('#{$url}kg.png');
  }
  .kh {
    background-image: url('#{$url}kh.png');
  }
  .ki {
    background-image: url('#{$url}ki.png');
  }
  .km {
    background-image: url('#{$url}km.png');
  }
  .kn {
    background-image: url('#{$url}kn.png');
  }
  .kp {
    background-image: url('#{$url}kp.png');
  }
  .kr {
    background-image: url('#{$url}kr.png');
  }
  .kw {
    background-image: url('#{$url}kw.png');
  }
  .ky {
    background-image: url('#{$url}ky.png');
  }
  .kz {
    background-image: url('#{$url}kz.png');
  }
  .la {
    background-image: url('#{$url}la.png');
  }
  .lb {
    background-image: url('#{$url}lb.png');
  }
  .lc {
    background-image: url('#{$url}lc.png');
  }
  .li {
    background-image: url('#{$url}li.png');
  }
  .lk {
    background-image: url('#{$url}lk.png');
  }
  .lr {
    background-image: url('#{$url}lr.png');
  }
  .ls {
    background-image: url('#{$url}ls.png');
  }
  .lt {
    background-image: url('#{$url}lt.png');
  }
  .lu {
    background-image: url('#{$url}lu.png');
  }
  .lv {
    background-image: url('#{$url}lv.png');
  }
  .ly {
    background-image: url('#{$url}ly.png');
  }
  .ma {
    background-image: url('#{$url}ma.png');
  }
  .mc {
    background-image: url('#{$url}mc.png');
  }
  .md {
    background-image: url('#{$url}md.png');
  }
  .me {
    background-image: url('#{$url}me.png');
  }
  .mf {
    background-image: url('#{$url}mf.png');
  }
  .mg {
    background-image: url('#{$url}mg.png');
  }
  .mh {
    background-image: url('#{$url}mh.png');
  }
  .mk {
    background-image: url('#{$url}mk.png');
  }
  .ml {
    background-image: url('#{$url}ml.png');
  }
  .mm {
    background-image: url('#{$url}mm.png');
  }
  .mn {
    background-image: url('#{$url}mn.png');
  }
  .mo {
    background-image: url('#{$url}mo.png');
  }
  .mp {
    background-image: url('#{$url}mp.png');
  }
  .mq {
    background-image: url('#{$url}fr.png');
  }
  .mr {
    background-image: url('#{$url}mr.png');
  }
  .ms {
    background-image: url('#{$url}ms.png');
  }
  .mt {
    background-image: url('#{$url}mt.png');
  }
  .mu {
    background-image: url('#{$url}mu.png');
  }
  .mv {
    background-image: url('#{$url}mv.png');
  }
  .mw {
    background-image: url('#{$url}mw.png');
  }
  .mx {
    background-image: url('#{$url}mx.png');
  }
  .my {
    background-image: url('#{$url}my.png');
  }
  .mz {
    background-image: url('#{$url}mz.png');
  }
  .na {
    background-image: url('#{$url}na.png');
  }
  .nc {
    background-image: url('#{$url}nc.png');
  }
  .ne {
    background-image: url('#{$url}ne.png');
  }
  .nf {
    background-image: url('#{$url}nf.png');
  }
  .ng {
    background-image: url('#{$url}ng.png');
  }
  .ni {
    background-image: url('#{$url}ni.png');
  }
  .nl {
    background-image: url('#{$url}nl.png');
  }
  .no {
    background-image: url('#{$url}no.png');
  }
  .np {
    background-image: url('#{$url}np.png');
  }
  .nr {
    background-image: url('#{$url}nr.png');
  }
  .nu {
    background-image: url('#{$url}nu.png');
  }
  .nz {
    background-image: url('#{$url}nz.png');
  }
  .om {
    background-image: url('#{$url}om.png');
  }
  .pa {
    background-image: url('#{$url}pa.png');
  }
  .pe {
    background-image: url('#{$url}pe.png');
  }
  .pf {
    background-image: url('#{$url}pf.png');
  }
  .pg {
    background-image: url('#{$url}pg.png');
  }
  .ph {
    background-image: url('#{$url}ph.png');
  }
  .pk {
    background-image: url('#{$url}pk.png');
  }
  .pl {
    background-image: url('#{$url}pl.png');
  }
  .pm {
    background-image: url('#{$url}pm.png');
  }
  .pn {
    background-image: url('#{$url}pn.png');
  }
  .pr {
    background-image: url('#{$url}pr.png');
  }
  .ps {
    background-image: url('#{$url}ps.png');
  }
  .pt {
    background-image: url('#{$url}pt.png');
  }
  .pw {
    background-image: url('#{$url}pw.png');
  }
  .py {
    background-image: url('#{$url}py.png');
  }
  .qa {
    background-image: url('#{$url}qa.png');
  }
  .re {
    background-image: url('#{$url}fr.png');
  }
  .ro {
    background-image: url('#{$url}ro.png');
  }
  .rs {
    background-image: url('#{$url}rs.png');
  }
  .ru {
    background-image: url('#{$url}ru.png');
  }
  .rw {
    background-image: url('#{$url}rw.png');
  }
  .sa {
    background-image: url('#{$url}sa.png');
  }
  .sb {
    background-image: url('#{$url}sb.png');
  }
  .sc {
    background-image: url('#{$url}sc.png');
  }
  .sd {
    background-image: url('#{$url}sd.png');
  }
  .se {
    background-image: url('#{$url}se.png');
  }
  .sg {
    background-image: url('#{$url}sg.png');
  }
  .sh {
    background-image: url('#{$url}sh.png');
  }
  .si {
    background-image: url('#{$url}si.png');
  }
  .sj {
    background-image: url('#{$url}sj.png');
  }
  .sk {
    background-image: url('#{$url}sk.png');
  }
  .sl {
    background-image: url('#{$url}sl.png');
  }
  .sm {
    background-image: url('#{$url}sm.png');
  }
  .sn {
    background-image: url('#{$url}sn.png');
  }
  .so {
    background-image: url('#{$url}so.png');
  }
  .sr {
    background-image: url('#{$url}sr.png');
  }
  .ss {
    background-image: url('#{$url}ss.png');
  }
  .st {
    background-image: url('#{$url}st.png');
  }
  .sv {
    background-image: url('#{$url}sv.png');
  }
  .sx {
    background-image: url('#{$url}sx.png');
  }
  .sy {
    background-image: url('#{$url}sy.png');
  }
  .sz {
    background-image: url('#{$url}sz.png');
  }
  .tc {
    background-image: url('#{$url}tc.png');
  }
  .td {
    background-image: url('#{$url}td.png');
  }
  .tf {
    background-image: url('#{$url}tf.png');
  }
  .tg {
    background-image: url('#{$url}tg.png');
  }
  .th {
    background-image: url('#{$url}th.png');
  }
  .tj {
    background-image: url('#{$url}tj.png');
  }
  .tk {
    background-image: url('#{$url}tk.png');
  }
  .tl {
    background-image: url('#{$url}tl.png');
  }
  .tm {
    background-image: url('#{$url}tm.png');
  }
  .tn {
    background-image: url('#{$url}tn.png');
  }
  .to {
    background-image: url('#{$url}to.png');
  }
  .tr {
    background-image: url('#{$url}tr.png');
  }
  .tt {
    background-image: url('#{$url}tt.png');
  }
  .tv {
    background-image: url('#{$url}tv.png');
  }
  .tw {
    background-image: url('#{$url}tw.png');
  }
  .tz {
    background-image: url('#{$url}tz.png');
  }
  .ua {
    background-image: url('#{$url}ua.png');
  }
  .ug {
    background-image: url('#{$url}ug.png');
  }
  .um {
    background-image: url('#{$url}um.png');
  }
  .un {
    background-image: url('#{$url}un.png');
  }
  .us {
    background-image: url('#{$url}us.png');
  }
  .uy {
    background-image: url('#{$url}uy.png');
  }
  .uz {
    background-image: url('#{$url}uz.png');
  }
  .va {
    background-image: url('#{$url}va.png');
  }
  .vc {
    background-image: url('#{$url}vc.png');
  }
  .ve {
    background-image: url('#{$url}ve.png');
  }
  .vg {
    background-image: url('#{$url}vg.png');
  }
  .vi {
    background-image: url('#{$url}vi.png');
  }
  .vn {
    background-image: url('#{$url}vn.png');
  }
  .vu {
    background-image: url('#{$url}vu.png');
  }
  .wf {
    background-image: url('#{$url}wf.png');
  }
  .ws {
    background-image: url('#{$url}ws.png');
  }
  .xk {
    background-image: url('#{$url}xk.png');
  }
  .ye {
    background-image: url('#{$url}ye.png');
  }
  .yt {
    background-image: url('#{$url}yt.png');
  }
  .za {
    background-image: url('#{$url}za.png');
  }
  .zm {
    background-image: url('#{$url}zm.png');
  }
  .zw {
    background-image: url('#{$url}zw.png');
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .hide {
    display: none;
  }
  .v-hide {
    visibility: hidden;
  }
}

@import '@popsure/dirty-swan/dist/grid';

.priceGrid {
  grid-template-columns: auto auto;
  row-gap: 12px;

  h2 {
    font-size: 24px;
  }
}

.blockOnMobile {
  @include p-size-mobile {
    display: block;
  }
}

.end {
  justify-self: end;
}

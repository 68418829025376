@import '@popsure/dirty-swan/dist/grid';

.container {
  max-width: 928px; // 896px + 2 * 16px padding
  padding: 48px 16px 24px 16px;
  border-radius: 8px;
}

.calculatorInputs {
  max-width: 440px;

  // Disable Chrome autofill background color
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
}

.calculatorOutput {
  max-width: 356px;
}

.quoteButton {
  min-width: 264px;
}

.badgeContainer {
  margin-top: 12px;
}

.footnote {
  margin-top: 4px;
}

.price {
  font-size: 48px;
}

@import '@popsure/dirty-swan/dist/grid';

.content {
  @include p-size-mobile {
    padding-left: 24px;
  }
}

.title {
  margin-bottom: 2px;
  transform: translateX(-24px);

  @include p-size-mobile {
    transform: translateX(0);
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.coveredCardWrapper {
  @include p-size-tablet {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  border: 1px solid $ds-grey-300;
}

.cardWrapper > div > div {
  flex-direction: column-reverse;
  gap: 24px;

  @include p-size-tablet {
    align-items: center;
    flex-direction: row;
    gap: 56px;
  }

  @include p-size-desktop {
    gap: 96px;
  }
}

.cardActionIcon {
  margin-left: 0;
}

.toggleLabel {
  padding-right: 0;
  flex-direction: column;
  align-items: center;
  min-height: max-content;

  @include p-size-tablet {
    flex-direction: row-reverse;
    width: 124px;
  }
}

.coveredIcon {
  width: 32px;
  height: 32px;
}

.notCoveredIcon {
  width: 24px;
  height: 24px;
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  padding: 8px;

  @include p-size-mobile {
    padding: 4px;
  }
}

.card {
  outline-width: 0!important;
  
  &:hover {
    outline-width: 0!important;
    background-color: $ds-primary-50;
  }

  &:focus-visible {
    outline-width: 1px!important;
    background-color: $ds-primary-50;
  }
}

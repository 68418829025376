@import '@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';
@import 'mixins';

.beneficiaryCard {
  width: 345px;
  flex-shrink: 2;
  @include p-size-mobile {
    width: 100%;
  }
}

.payoutInput {
  width: 108px;
}

.payoutSplitEuro {
  width: 132px;
  flex: 0 0 auto;
}

.payoutSplit {
  @include p-size-mobile {
    width: 100%;
    margin-left: 0;
  }
  padding: 20px;
  height: 64px;
}

.addBeneficiaryButton {
  border: 1px dashed $ds-primary-500;
  width: 345px;
  padding: 20px 24px;
  height: auto;

  @include p-size-mobile {
    width: 100%;
  }
}

.beneficiaries {
  @include p-size-mobile {
    flex-direction: column;
    gap: 16px;
    align-items: end;
  }
}

.container {
  background-color: white;

  height: 64px;

  box-shadow: 0px 2px 20px rgba(97, 96, 162, 0.07);

  border-radius: 8px;
  border: 2px solid white;

  transition: border 0.2s ease-in-out;

  display: flex;
  justify-content: space-between;
  align-items: center;

  text-decoration: none;

  &:hover {
    border: 2px solid $ds-primary-300;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.25;
  }

  &:focus {
    border: 2px solid $ds-primary-500;
  }
}

.xIcon {
  display: block;
  @include p-size-mobile {
    display: none;
  }
}

.removeButton {
  display: none;
  @include p-size-mobile {
    display: block;
  }
}

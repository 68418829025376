@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.wrapper {
  height: 88px;
  
  @include p-size-desktop {
    height: inherit;
  }
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 72px;

  display: flex;
  align-items: center;

  background-color: $ds-white;
  border-bottom: 1px solid $ds-grey-300;
  padding: 0 16px;
  z-index: 99;

  &MobileApp {
    height: 88px;
    padding: 8px 16px;
    align-items: flex-end;
  }

  @include p-size-desktop {
    height: inherit;
    background-color: transparent;
    border-bottom: 0;
    position: static;
    padding: 24px 0;

    &Tall {
      padding: 48px 0 8px;
    }
  }
}

.button {
  color: $ds-grey-600;
  transition: 0.3s color;

  &:hover,
  &:focus-visible {
    color: $ds-purple-500;
  }
}

.title {
  visibility: hidden;

  @include p-size-desktop {
    visibility: visible;
  }
}
@import '@popsure/dirty-swan/dist/colors';

.grid {
  grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));
}

.cta {
  height: 40px;
  padding: 8px 24px;
}

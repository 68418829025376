.container {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;
  text-align: center;

  &::before {
    display: inline-block;
    content: '';
    width: 80px;
    height: 70px;
    background: no-repeat url('./icons/something-went-wrong.svg');
  }
}

.button {
  min-width: 212px;
  margin: 8px;
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  min-height: calc(100vh - 154px);
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  padding: 16px 24px 24px;

  @include p-size-mobile {
    padding: 16px 16px 110px;
  }
}

.qr-container {
  border-radius: 8px;
}

.button {
  font-family: inherit;
  display: none;

  @include p-size-desktop {
    display: flex;
  }
}

.loader-container {
  position: relative;
  color: $ds-primary-500;
  margin-left: auto;
  width: 32px;
}

.phonePhoto {
  width: 28px;
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  padding: 0 24px 24px 24px;

  @include p-size-mobile {
    padding: 0 16px 16px 16px;
  }
}

.button-container {
  column-gap: 16px;
  row-gap: 10px;

  @include p-size-mobile {
    a,
    button {
      width: 100%;
    }
  }
}

.secondary-button {
  background: $ds-primary-50;
}

.sign-up-public-button {
  cursor: pointer;
  background: transparent;
}

@import '@popsure/dirty-swan/dist/colors';

.container {
  position: relative;
}

.signature-container {
  width: 100%;
  height: 200px;
  background-color: white;
  border: 1px solid $ds-grey-400;
  border-radius: 4px;
}

.button-container {
  position: absolute;
  right: 0;
  margin-top: 8px;
}

.signature-button {
  height: 16px !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 0 16px;
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-tablet {
    @include question-container;
  }

  @include p-size-mobile {
    margin: 80px 0;
  }
}

.button {
  @include p-size-mobile {
    max-width: 100%;
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.card {
  margin-bottom: 24px;
  @include appear-in;

  @include p-size-tablet {
    margin-bottom: 32px;
  }
}

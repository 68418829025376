@import '@popsure/dirty-swan/dist/grid';

.image {
  max-width: 436px;
}

.titleContainer {
  max-width: 436px;
}

.description {
  margin-top: 4px;
}

.cardGrid {
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));

  @include p-size-mobile {
    margin-top: 24px;
    row-gap: 16px;
  }
}

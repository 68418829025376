@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-container {
  @include appear-in;
}

.form-container {
  @include appear-in-with-delay;
}

.description-input-container {
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px;
}

.description {
  font-family: inherit;

  min-height: 72px;
  max-height: 160px;

  padding: 8px;

  resize: vertical;

  @include p-size-mobile {
    width: 100% !important;
    resize: none;
  }
}

.submit-button {
  margin-bottom: 120px;
}

@import '@popsure/dirty-swan/dist/colors';

.phone-input {
  width: 100%;
}

.global-error-message {
  font-size: 1.125rem;
  color: $ds-red-500;
  text-align: center;
}

@import '~@popsure/dirty-swan/dist/colors';

.section-bottom {
  background-color: $ds-grey-200;
  padding: 56px 0;
}

.section-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-icon {
  width: 64px;
  height: 64px;

  margin: 0 auto;
}

.statement-links-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--center {
    justify-content: center;
  }
}

.divider {
  height: 32px;
  width: 1px;
  background-color: $ds-grey-500;
  margin: 0 24px;
}

.policy-link {
  display: flex;
  align-items: center;
}

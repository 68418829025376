@import '~@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';
@import 'mixins';

.send-code-button {
  border: none;
  background-color: transparent;
  font-family: inherit;
}

.signin-button {
  @include p-size-mobile {
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/grid';

.row {
  @include p-size-tablet {
    width: 364px;
  }

  @include p-size-mobile {
    max-width: 364px;
  }
}

.coverageItem:last-child {
  @include p-size-desktop {
    margin-bottom: 0;
  }
}

@import '~@popsure/dirty-swan/dist/colors';

.space-between {
  justify-content: space-between;
}

.align-baseline {
  align-items: baseline;
}

.employer {
  border-top: 1px solid $ds-grey-200;
  padding-top: 24px;
}

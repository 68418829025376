@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import "variables";

.container-logged-in {
  background-color: white;

  border-top: 1px solid $ds-grey-300;

  position: fixed;
  
  height: 80px;
  padding: 0 24px;

  left: 0;
  right: 0;
  bottom: 0;

  z-index: 100;

}

.container-logged-out {
  display: flex;
  position: relative;

  height: $navbarHeight;
  width: 100%;

  background-color: white;
  border-bottom: 1px solid $ds-grey-300;

  z-index: 90
}

.content {
  display: flex;

  height: 100%;
  width: 100%;

  justify-content: space-between;
  align-items: center;

  padding: 0 24px;
  margin: 0 auto;
}

.content-authenticated {
  display: flex;

  justify-content: space-between;

  height: 100%;

  margin: 0 auto;

  align-items: center;
}

.logo {
  display: block;
}

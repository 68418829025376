@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  padding: 0 16px;
}

.header {
  @include p-size-mobile {
    margin-top: 24px !important;
  }
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
}

.pill {
  border-radius: 24px;
  border: 1px solid $ds-grey-400;
  background-color: transparent;

  cursor: pointer;

  width: fit-content;
  padding: 0 16px;
  margin-right: 8px;
  font-family: inherit;
}

.pill:hover {
  border: 1px solid $ds-purple-500;
  transition: 0.2s ease-in;
}

.selected-pill {
  background-color: $ds-purple-500;
  color: white !important;
  border: 1px solid $ds-purple-500;
}

.type-info-container {
  background-color: $ds-yellow-100;
  border: 1px solid $ds-yellow-500;
  border-radius: 8px;

  padding: 16px;

  transition: height 2s;
}

.type-info-details {
  display: flex;
  justify-content: space-between;
}

.button {
  margin-bottom: 120px;
}

@import '@popsure/dirty-swan/dist/colors';

.badge {
  background: $ds-purple-100;
  border-radius: 8px;
  padding: 6px 16px;
  text-decoration: none;
  transition: all 0.3s ease-in-out !important;

  &:hover {
    background: $ds-purple-300;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.modal-content-container {
  padding: 0 24px 24px 24px;
}

.list {
  list-style: disc inside;
}

@import '@popsure/dirty-swan/dist/colors';

.container {
  cursor: pointer;

  display: flex;
  align-items: center;

  border-radius: 8px;

  border: 1px solid $ds-grey-400;

  transition: 0.3s background-color, border-color;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 48px;
}

.icon {
  position: absolute;
  right: 0;
  margin-left: auto;
  margin-right: 16px;
  opacity: 0;
}

.container {
  position: relative;

  &:hover {
    border-color: $ds-purple-500;

    .icon {
      @keyframes appearInAnimation {
        from {
          opacity: 0;
          transform: translateY(-5px);
        }
        to {
          opacity: 1;
        }
      }


      animation-name: appearInAnimation;
      animation-duration: 0.3s;
      animation-fill-mode: both;
    }
  }
}
.documentsWrapper {
  a {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

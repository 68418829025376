@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  padding: 0 16px;
}

.header {
  @include p-size-mobile {
    margin-top: 24px !important;
  }
}

.chip {
  border-radius: 24px;
  background-color: $ds-purple-300;

  height: 24px;
  width: fit-content;

  padding: 0 40px;
  line-height: 24px !important;
}

.claim-text-area {
  font-family: inherit;

  min-height: 72px;
  max-height: 160px;

  padding: 8px;

  @include p-size-mobile {
    max-width: 100% !important;
  }
}

.link {
  text-decoration: none;
}

.button {
  margin-bottom: 96px;
  @include p-size-mobile {
    width: 100%;
  }
}

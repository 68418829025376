@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.deleteAccountModal {
  @include p-size-mobile {
    padding: 0 16px 16px 16px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
}

@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  justify-content: center;

  pointer-events: none;
}

.step {
  width: 32px;
  height: 32px;

  border-radius: 50%;
  border: 2px solid $ds-grey-400;

  color: $ds-grey-400;

  display: flex;
  align-items: center;
  justify-content: center;
}

.current-step {
  color: white;
  background-color: $ds-purple-500;
  border: 2px solid $ds-purple-500;
}

.bar {
  width: 100px;
  height: 2px;
  background-color: $ds-grey-400;

  display: flex;
  align-self: center;
}

.current-bar {
  background-color: $ds-purple-500;
}

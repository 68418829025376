@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include question-container;
}

.contentContainer {
  min-height: 160px;
}

@mixin question-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

@mixin full-min-height {
  @include p-size-tablet {
    // calculates 100 view height
    // minus navbar height, minus app/content spacing and label padding * 2
    min-height: calc(100vh - $navbarHeight - 232px);
  }
}

@mixin appear-in {
  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

@mixin appear-in-with-delay {
  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.7s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

@keyframes appear-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin fade-in {
  animation-name: fade-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

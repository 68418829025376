@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.button {
  color: $ds-grey-500;
}

.header {
  @include p-size-mobile {
    font-size: 18px;
  }
}
@import '@popsure/dirty-swan/dist/grid';

.cellValue {
  word-break: break-word;

  @include p-size-mobile {
    max-width: 120px;
  }
}


.noBreak {
  word-break: normal;
  white-space: nowrap;
  max-width: 100% !important;
}
@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.row {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.chevronOpen {
  transition: filter 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.chevronClosed {
  transform: rotate(180deg);
}

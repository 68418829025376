@import '@popsure/dirty-swan/dist/grid';

.buttonContainer {
  display: grid;
  row-gap: 16px;

  margin-top: 32px;

  @include p-size-tablet {
    max-width: 80%;
    margin-top: 48px;
  }

  @include p-size-desktop {
    max-width: 100%;
    column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
}

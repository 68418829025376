@import '@popsure/dirty-swan/dist/grid';

@keyframes container-appear {
  from {
    filter: saturate(0) brightness(1);
    opacity: 0.5;
  }
  to {
    filter: brightness(1);
    opacity: 1;
  }
}

@mixin headerAnimation {
  animation: container-appear 0.2s ease-out 1 normal both;
}


.wrapper {
  position: relative;
  margin-bottom: 24px;
  @include headerAnimation;

  @include p-size-tablet {
    padding-top: 32px;
  }

  @include p-size-desktop {
    padding-top: 40px;
    margin-bottom: 40px;
  }
}

.navWrapper {
  @include p-size-desktop {
    padding-top: 32px;
    @include headerAnimation;
  }
}


.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  width: 100%;
  height: 120px;

  z-index: -1;
}


.card {
  position: relative;

  @include p-size-desktop {
    padding: 40px 40px 48px 32px;
  }
}

.contentWrapper {
  margin-right: 16px;

  @include p-size-desktop {
    width: 100%;
    margin-left: 200px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.title {
  hyphens: auto;
  word-break: break-word;
}

.icon {
  width: 72px;
  height: 72px;

  @include p-size-tablet {
    width: 116px;
    height: 116px;
  }

  @include p-size-desktop {
    position: absolute;
    top: -40px;
    left: 32px;

    width: 160px;
    height: 160px;
  }
}

.my4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.subtitlesContainer {
  @include p-size-tablet {
    display: flex;
    align-items: center;
    column-gap: 4px;
    margin-top: 4px;
  }
}

.policyHolder {
  margin: 4px 0;

  @include p-size-tablet {
    margin: 0;
  }
}

.price {
  @include p-size-tablet {
    &:before {
      content: '·';
      margin-right: 4px;
    }
  }
}

.companyName {
  @include p-size-tablet {
    &:after {
      content: '·';
      margin-left: 4px;
    }
  }
}

.badge {
  margin-top: 24px;

  @include p-size-tablet {
    margin-top: 16px;
  }

  @include p-size-desktop {
    margin-top: 0;
  }
}

.tooltip {
  @include p-size-mobile {
    max-width: 280px;
  }
}

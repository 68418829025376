@import '@popsure/dirty-swan/dist/colors';
@import 'mixins';

.container {
  min-height: calc(100vh - 154px);
  @include fade-in;

  p + p {
    margin-top: 24px;
  }
}
@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  background: #fff;
  border-radius: 12px;

  @include p-size-mobile {
    padding: 16px;
  }
}

.icon {
  border-radius: 12px;
  left: 50%;
  position: absolute;
  top: -80px;
  transform: translateX(-50%);
}

.button {
  background: transparent;
  border: none;
  column-gap: 10px;
  outline: none;
  transition: color 0.3s ease-in-out;
  color: $ds-grey-500;

  img {
    transition: filter 0.3s ease-in-out;
  }

  &:hover {
    color: $ds-grey-700;

    img {
      filter: brightness(0.425);
    }
  }
}

.title {
  order: 1;
  font-size: 24px;
  margin-top: 88px; // 64px + 24px

  @include p-size-tablet {
    margin-top: 64px;
  }

  @include p-size-desktop {
    margin-top: 24px;
    order: 2;
  }
}

.markdown {
  order: 2;

  @include p-size-desktop {
    order: 3;
  }

  a {
    transition: color 0.3s ease-in-out;
  }

  strong {
    font-weight: 700;
  }
}

.buttonsWrapper {
  order: 3;
  align-items: center;
  flex-flow: column;

  button,
  a {
    column-gap: 8px;
  }

  button {
    margin: 16px 0;
  }

  @include p-size-desktop {
    align-items: baseline;
    order: 1;
    flex-flow: row;
    justify-content: space-between;

    button {
      margin: 0;
    }
  }
}

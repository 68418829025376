@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.innerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 48px;

  @include p-size-desktop {
    padding-bottom: 0;
  }
}

.logo {
  width: 62.5px;
  height: 80px;

  @include p-size-mobile {
    width: 50px;
    height: 64px;
  }
}

.btnContMobile {
  position: absolute;
  bottom: 40px;
  left: 0;
  display: flex;
  flex-direction: column;

  @include p-size-tablet {
    display: none;
  }
}

.btnContDesktop {
  display: flex;

  @include p-size-mobile {
    display: none;
  }
}

@import '~@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';

.container {
  flex-direction: column;

  @include p-size-tablet {
    flex-direction: row;
  }
}

.icon {
  margin-top: -48px;
  width: 72px;
  height: 72px;

  @include p-size-tablet {
    margin-top: -60px;
    width: 112px;
    height: 112px;
  }
}

.colorBanner {
  height: 88px;

  @include p-size-tablet {
    height: 132px;
  }
}

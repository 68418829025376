@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.apvItem {
  border: 2px solid var(--Border, $ds-grey-300);
  text-decoration: none;

  @include p-size-mobile {
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.mobileHidden {
  @include p-size-mobile {
    display: none;
  }
}

.mobileDisplay {
  display: none;

  @include p-size-mobile {
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
  }
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

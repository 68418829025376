@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

$background-color: #f0f0f5;
$glow-color: #f7f5faff;
$background-gradient: linear-gradient(90deg, $background-color 0%, $glow-color 45%, $background-color 100%);

@keyframes placeholderGlowAnimation {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@mixin placeholderGlow($delay) {
  animation: placeholderGlowAnimation 4s ease-in-out infinite;
  animation-delay: $delay;

  background: $background-gradient, 0, 200%;
  background-size: 200%;
}

.background {
  @include placeholderGlow(0s);

  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  width: 100%;
  height: 205px;

  z-index: -1;


  @include p-size-desktop {
    height: 224px;
  }
}


.wrapper {
  position: relative;
  margin-bottom: 24px;
  padding-top: 88px;

  @include p-size-tablet {
    padding-top: 120px;
  }

  @include p-size-desktop {
    padding-top: 144px;
    margin-bottom: 40px;
  }
}

.card {
  position: relative;
  height: 162px;
  display: flex;
  padding: 24px;

  @include p-size-desktop {
    height: 152px;
    padding: 40px 40px 48px 32px;
  }
}

.icon {
  background-color: #f0f0f7;

  width: 72px;
  height: 72px;

  order: 2;

  margin-left: auto;

  @include p-size-tablet {
    width: 116px;
    height: 116px;
  }

  @include p-size-desktop {
    position: absolute;
    top: -40px;
    left: 32px;

    width: 160px;
    height: 160px;
  }
}

.title {
  @include placeholderGlow(0.5s);

  opacity: 0.75;

  width: 150px;
  height: 34px;

  @include p-size-tablet {
    width: 200px;
    height: 42px;
  }

  @include p-size-desktop {
    margin-left: 200px;
  }
}

.subtitle {
  @include placeholderGlow(0.25s);
  opacity: 0.6;

  height: 18px;
  width: 75px;

  margin-top: 8px;

  @include p-size-desktop {
    margin-left: 200px;
  }
}

.section {
  height: 400px;
}

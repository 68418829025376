@import '@popsure/dirty-swan/dist/grid';

.info-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include p-size-desktop {
    justify-content: space-between;
  }
}

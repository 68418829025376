@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.textbox {
  padding: 8px 16px;
  min-height: 200px;
  max-height: 280px;

  font-family: inherit;
  resize: vertical;

  @include p-size-mobile {
    max-width: 100%;
    width: 100%;
  }
}

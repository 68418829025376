@import '@popsure/dirty-swan/dist/grid';

.container {
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(97, 96, 162, 0.01);

  @include p-size-mobile {
    margin-top: 16px;
    padding: 24px;
    margin-top: 16px;
  }
}

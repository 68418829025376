@import '~@popsure/dirty-swan/dist/grid';

.container {
  width: 100%;
}

.inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 96px;
  height: 96px;
}

.button {
  @include p-size-mobile {
    margin-bottom: 80px;
  }
}

.feedback {
  opacity: 0;
  min-height: 16px;
  transform: translateY(-100%);
  transition: all 0.3s ease;
}

.feedback-active {
  opacity: 1;
  transform: translateY(0%);
}

.cardLink {
  text-decoration: none;
}

@property --num1 {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.price::after {
  counter-reset: num1 var(--num1);
  content: counter(num1);
  animation: counter 5s ease-in-out forwards;
}

@keyframes counter {
  0% {
    --num1: 66;
  }
  18% {
    --num1: 66;
  }
  100% {
    --num1: 0;
  }
}

.progress {
  box-shadow: 0px 2px 28px rgba(97, 96, 162, 0.1);
  transition: border-color 0.3s;
  height: 10px;

  & p {
    @extend .progress;
    animation: load 5s ease-in-out forwards;
    height: 10px;
    width: 42%;
  }
}

@keyframes load {
  0% {
    width: 42%;
  }
  18% {
    width: 42%;
  }
  100% {
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.icon {
  width: 80px;
  height: 80px;
}

.columnItem {
  flex: 1;
  max-width: calc(50% - 16px);

  @include p-size-mobile {
    flex-basis: auto;
  }

  @media (max-width: 780px) {
    min-width: 100%;
    max-width: 100%;
  }
}

.buttonContainer {
  @include p-size-mobile {
    margin-top: 16px !important;
  }
}

.buttonDocsContainer {
  @media (min-width: 700px) {
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    background-color: $ds-white;
    padding: 24px;
    border-radius: 8px;
  }
}

.submitPage {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);
  row-gap: 20px;

  @include p-size-desktop {
    flex-direction: row;
    justify-content: space-between;
  }
}

.submitSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px;
  min-height: 100%;

  @include p-size-desktop {
    margin-bottom: -80px;
    width: 50%;
  }

  @include p-size-tablet {
    padding: 64px 96px;
  }
}

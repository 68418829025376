@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';


.container {
  @include p-size-tablet {
    min-width: 516px;
  }
}

.userActionWrapper {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.textarea {
  min-height: 150px;
  max-height: 300px;

  font-family: inherit;
  resize: vertical;

  white-space: pre-wrap;
}

.link {
  text-decoration: none;
}

.file {
  text-decoration: none;
  border: 1px solid $ds-grey-400;
}


.richTextContainer {
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.25em;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0.25em;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.25em;
  }

  p {
    margin-bottom: 0.25em;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.5em;
  }

  ul {
    list-style-type: disc;
    padding-left: 1.75em;
  }

  em {
    font-style: italic;
  }

  b, strong {
    font-weight: 700;
  }

  u {
    text-decoration: underline;
  }

  a {
    color: $ds-purple-500;
    text-decoration: underline;
  }
}

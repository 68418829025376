@import '~@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.divider {
  border: 1px solid $ds-grey-200;
  margin-bottom: 16px;
}

.header {
  padding: 16px 24px;
  background-color: $ds-primary-100;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 42px;

  display: flex;
  align-items: center;
}

.finalPriceRow {
  display: flex;
  flex-direction: column;

  @include p-size-tablet {
    flex-direction: row;
    align-items: center;
  }
}

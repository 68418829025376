@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.content {
  border: 1px solid $ds-grey-300;
  flex-direction: column-reverse;

  @include p-size-tablet {
    flex-direction: row;
    gap: 16px;
  }
}

.selected {
  border: 1px solid $ds-primary-500;
  background-color: $ds-primary-50 !important;
}

.heading {
  @include p-size-tablet {
    flex-direction: column;
  }
}

.description {
  max-width: 544px;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.toggleContainer {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  min-width: 30%;

  @include p-size-tablet {
    flex-direction: row-reverse;
    gap: 16px;

    h3 {
      margin-top: 0;
    }
  }
}

.toggleLabel {
  min-height: 12px;
  padding-right: 0;
}

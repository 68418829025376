@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  margin-top: 48px;

  @include p-size-desktop {
    margin-top: 80px;
  }
}

.referFriendButton {
  @include p-size-mobile {
    display: none;
  }
}

.cardGrid {
  @include p-size-desktop {
    grid-template-columns: repeat(2, 1fr);
  }
}

.cardGridFullWidth {
  @include p-size-desktop {
    grid-template-columns: repeat(1, 1fr);
  }
}
.container {
  max-width: 928px; // 896px + 2 * 16px padding
  min-height: calc(100vh - 154px);
  padding: 0 16px;

  &MobileApp {
    padding-top: 82px;
  }
}


.grid {
  grid-template-columns: repeat(auto-fit, minmax(min(288px, 100%), 1fr));
}

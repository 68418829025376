@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

body {
  // Disable horizontal scrolling in Chromium-based browser dev tool mobile views
  overscroll-behavior-x: none !important;
}

:root {
  --popsure-box-shadow-raised: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
  --popsure-box-shadow-hover: 0 2px 9px 0 rgba(0, 0, 0, 0.25);
}

:root {
  --font-weight-normal: 400;
  --font-weight-bold: 700;
}

* {
  vertical-align: baseline;
  outline: 0;
  padding: 0;
  margin: 0;
  font-weight: var(--font-weight-normal);
  line-height: 24px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.root-container {
  margin-top: 40px;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 80px;
}

@media screen and (min-width: 720px) {
  .root-container {
    max-width: 700px;
    margin: 56px auto 0 auto;
  }
}

a {
  color: $ds-grey-700;
  text-decoration: underline;
}

.hyphen {
  @include p-size-mobile {
    display: block;

    &:after {
      content: '-';
    }
  }
}

body.mobile-app > div > iframe {
  padding-top: 64px;
}

// TODO: [KONG] this is the new class for components that will have button tag for interactive purposes
// This class should have its own accessibility features that differ from an actual DS button

// Classes to create:
// 1. Component class to remove button styles
.ds-interactive-component {
  background: transparent;
  border:none;

  cursor: pointer;

  font-family: inherit;
  text-align: inherit;

  &:focus-visible {
    outline: 1px solid $ds-primary-500;
    border-radius: 4px;
  }
}
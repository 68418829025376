@import '@popsure/dirty-swan/dist/grid';

.content {
  @include p-size-mobile {
    flex-direction: column;
  }
}

.subtitle {
  @include p-size-mobile {
    order: -1;
  }
}

.description {
  max-width: 544px;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.toggleContainer {
  @include p-size-mobile {
    order: -1;
    align-items: center;
  }
}

.toggleLabel {
  min-height: 12px;
  padding-right: 0;
}
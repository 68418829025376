@import '@popsure/dirty-swan/dist/grid';
@import 'variables';

.container {
  min-height: calc(100vh - $navbarHeight * 2);
}

.content {
  // Overrides flex-box min-width: auto to make content "shrinkable" when centered
  min-width: 0px;
}

.card {
  padding: 24px;

  @include p-size-tablet {
    padding: 32px;
  }
}

.columnOnMobile {
  flex-direction: column;

  @include p-size-tablet {
    flex-direction: row;
  }
}

.incomeProtection {
  gap: 4px;
  margin-top: 8px;

  @include p-size-tablet {
    margin-top: 16px;
  }
}

.divider {
  height: 1px;
}

@import '@popsure/dirty-swan/dist/grid';

.priceGrid {
  grid-template-columns: auto auto;
  row-gap: 12px;
}

.tinyGap {
  column-gap: 2px;
}

.end {
  justify-self: end;
}

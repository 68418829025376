@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  max-width: 744px;
  margin: 88px auto 0 auto;

  @include p-size-mobile {
    margin-top: 56px;
    margin-bottom: 88px;
  }
}

.signature-container {
  background-color: white;
  border-radius: 8px;
  margin-top: 16px;
  padding: 24px;
  box-shadow: 0px 2px 3px rgba(38, 38, 46, 0.04);
  overflow: auto;
}

.error {
  text-align: right;
  color: $ds-red-500;
}

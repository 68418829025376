@import '@popsure/dirty-swan/dist/colors';

// ------------------------------------- CoverageModalRegular -------------------------------------
.headerTable {
  text-align: left;
  vertical-align: top;
  table-layout: fixed;

  th {
    padding: 32px 16px;
  }
}

// ------------------------------------- CoverageModalTabbed -------------------------------------

// This class exists to ensure we consistently override the modal container's default width of "fit-content" in staging/production.
// In development, simply using a "w100" as the className is sufficient, but in other environments the behavior becomes somewhat erratic
// (as webpack's CSS import order and by proxy the CSS rule cascade are affected by a plethora of factors).

.modalWidthOverride {
  & > div > div {
    width: 100% !important;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;
}

.tabbedHeaderTable {
  table-layout: fixed;
  text-align: center;
  vertical-align: top;

  th {
    font-size: 16px !important;
  }
}

.scrollContainer {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  width: 100%;

  // Disable the scrollbar in all browsers
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}
// ------------------------------------- Shared -------------------------------------

.iconCell {
  text-align: center;
  vertical-align: middle;
}

.modalContent {
  max-width: calc(100vw - 16px);
  row-gap: 4px;
}

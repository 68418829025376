@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.policyCard {
  width: 100%;
  border-width: 1px;
  text-decoration: none;

  &:hover,
  &:focus {
    border: 1px solid $ds-primary-500;
  }

  &NoHover:hover {
    border-color: transparent;
  }

  &:focus {
    border-color: transparent;
    outline-width: 2px;
  }

  @include p-size-desktop {
    width: 440px;
  }

  @include p-size-mobile {
    align-items: flex-start;
    flex-direction: row-reverse;
  }
}

.policyCardNoAction {
  border: 1px solid transparent;

  &:hover,
  &:focus {
    border-color: transparent;
    outline-color: transparent;
  }
}

.cardContent {
  align-items: center;

  @include p-size-mobile {
    align-items: initial;
    justify-content: flex-start;
    flex-direction: column;
  }
}

.cardImage {
  width: 88px;
  height: 88px;

  @include p-size-mobile {
    width: 48px;
    height: 48px;
  }
}

.contentItem {
  margin-top: 2px;
}
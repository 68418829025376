@import '@popsure/dirty-swan/dist/colors';

.ulist {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 16px;

  > li {
    padding-left: 8px;
  }
}

.olist {
  list-style-type: decimal;
  list-style-position: inside;
}

@import '@popsure/dirty-swan/dist/colors';

.button {
  background-color: $ds-white;
  border: 1px solid $ds-grey-400;

  &:hover {
    background-color: $ds-grey-300;
  }

  &:disabled,
  &:disabled:hover {
    background-color: $ds-white;
  }
}
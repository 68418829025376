@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include question-container;
}

.innerContainer {
  width: 424px;

  @include p-size-mobile {
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';

.headerUnderline {
  height: 4px;
  background-color: $ds-primary-500;
}

.tableHeader {
  display: flex;
  gap: 4px;
  @include p-size-tablet {
    display: none;
  }
}

.container {
  display: flex;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;

  margin-right: 1px;
}

@import 'variables';
@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  position: relative;

  height: $navbarHeight;
  width: 100%;

  background-color: white;
  border-bottom: 1px solid $ds-grey-300;
  z-index: 90;
}

.content {
  display: flex;

  height: 100%;
  width: 100%;

  position: relative;
  margin: 0 auto;
  padding: 0 32px;

  justify-content: flex-end;
  align-items: center;

  &.contentLoggedIn {
    justify-content: center;
  }
}

.contentMaxWidth {
  max-width: 1124px;
}

.logo {
  position: absolute;
  left: 32px;
}

.button-container {
  display: flex;

  & > a {
    margin: 0 16px;
  }
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';

.footer {
  @include p-size-mobile {
    flex-direction: column;
  }
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

.feedback {
  width: 100%;
  text-align: center;
  padding-top: 12px;
  opacity: 0;
  min-height: 16px;
  transform: translateY(-100%);
  transition: all 0.3s ease;
}

.feedbackActive {
  opacity: 1;
  transform: translateY(0%);
}

.getAllToggle {
  border-radius: 8px 8px 0 0;
}

.getAllToggle::after {
  content: "";
  display: block;
  height: 1px;
  margin: 0 16px;
  background: var(--ds-grey-300);
}

.topicsToggle {
  border-radius: 0 0 8px 8px;
}

.label > span {
  min-width: 40px;
}

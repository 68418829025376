.coverageDetails {
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.iconGrey {
  filter: grayscale(100%);
}

.smallIcon {
  width: 16px;
  height: 16px;
}

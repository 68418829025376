@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.addOnsContainer {
  @include p-size-tablet {
    margin-bottom: 24px;
  }
}

.addOnsHeader {
  display: flex;
  gap: 4px;

  @include p-size-tablet {
    align-items: start;
  }
}

.headerUnderline {
  height: 4px;
  background-color: $ds-primary-500;

  @include p-size-tablet {
    width: 212px;
  }
}

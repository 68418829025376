@import '@popsure/dirty-swan/dist/grid';

.container {
  padding: 56px 48px;
  border-radius: 8px;

  @include p-size-mobile {
    padding: 32px 24px;
  }
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include p-size-mobile {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include p-size-tablet {
    margin-right: 24px;
  }

  @include p-size-mobile {
    margin: 0;
  }

  &--disabled {
    opacity: 0.3;
  }
}

.cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radio-container {
  display: flex;
  flex-direction: column;

  width: fit-content;

  @include p-size-mobile {
    margin-top: 40px;
  }
}

.label-container {
  @include p-size-tablet {
    display: flex;
    flex-wrap: wrap;
  }
}

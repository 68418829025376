@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';
@import 'mixins';
@import 'variables';

.container {
  @include p-size-tablet {
    margin-bottom: 48px;
  }

  @include p-size-desktop {
    margin-bottom: 0px;
  }
}

.contentContainer {
  @include full-min-height;
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/grid';

.coverage-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include p-size-desktop {
    justify-content: space-between;
  }
}

.titleContainer {
  @include p-size-mobile {
    align-items: center;
    flex-direction: column;
  }
}

.sectionHeaderContainer {
  @include p-size-mobile {
    align-items: center;
  }
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';

.container {
  @include fade-in;
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}
@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.logoIcon {
  width: 64px;
  height: 64px;
}

.divider {
  height: 32px;
  width: 1px;
  background-color: $ds-grey-500;
}

.fixedPriceBar {
  position: fixed;
  height: 96px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
  background-color: $ds-primary-50;
  border-top: 1px solid $ds-primary-100;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.mobileSticky {
  display: flex;

  @include p-size-tablet {
    display: none;
  }
}

.desktopSticky {
  display: none;

  @include p-size-tablet {
    display: flex;
  }
}

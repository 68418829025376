@import '~@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.row {
  border-bottom: 1px solid $ds-grey-200;
}

.checkboxContent {
  min-height: 24px;
}

.container {
  position: relative;
}

.button {
  position: absolute;
  right: -32px;
  top: 0;
  padding: 0;
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  @include p-size-mobile {
    width: 100%;
  }
}

.paymentAmountTime {
  margin-top: 32px;

  @include p-size-mobile {
    margin-top: 0;
  }
}

.divider {
  height: 1px;
}

.paymentDetails {
  border: 1px solid $ds-grey-400;
}

.billingPeriod {
  @include p-size-mobile {
    max-width: 120px;
  }
}

.brandIcon {
  width: 60px;
  height: 40px;

  border-radius: 2px;
}

.statusBadge {
  @include p-size-mobile {
    display: block;
    margin-top: 16px;
  }
}

.paymentStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include p-size-mobile {
    display: initial;
  }

  .paypalEmail {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 132px;
  }
}

@import '~@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';

.container {
  grid-template-columns: repeat(auto-fit, minmax(min(340px, 100%), 1fr));
}

.card {
  border: 1px solid $ds-purple-100;
  justify-content: flex-start;
}

.icon {
  align-self: flex-start;
}

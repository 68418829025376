@import '~@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';

.ctaButton {
  color: $ds-grey-900;
  border: 1px solid $ds-grey-400;

  transition: all 0.3 ease-in-out;

  @include p-size-mobile {
    max-width: calc(100% - 96px);
  }

  &:disabled {
    border: none;
    background-color: $ds-grey-200;
    color: $ds-grey-900;
    opacity: 1;
    &:hover {
      background-color: $ds-grey-200;
    }
  }

  @include p-size-mobile {
    pointer-events: none;
  }
}

.selected {
  transition: all 0.3 ease-in-out;
  border: 2px solid $ds-purple-500;
  background-color: $ds-purple-50;
}

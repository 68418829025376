@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: white;
  border-radius: 8px;
  border: 1px solid white;
  box-shadow: 0px 2px 3px rgba(38, 38, 46, 0.04);

  padding: 8px 16px;

  cursor: pointer;
  text-decoration: none;

  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.container:hover {
  border: 1px solid $ds-purple-300;
  transition: 0.3s ease-in;
}

.claim-type {
  @include p-size-mobile {
    font-size: 12px !important;
  }
}

.status {
  width: 88px;
  border-radius: 24px;

  text-align: center;
}

.pending-status {
  background-color: #e8c973;
}

.paid-status {
  background-color: #9ddf85;
}

.closed-status {
  background-color: $ds-red-300;
}

@keyframes appear-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.paymentMethodIcon {
  height: 24px;
  width: 36px;
  padding: 2px;
}

.paymentMethodIdentifier {
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  display: flex;
  align-items: center !important;
  width: 100%;
}

.expireDate {
  margin-left: auto;
  margin-right: 0;
}

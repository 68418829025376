@import '@popsure/dirty-swan/dist/grid';

.container {
  padding: 16px 32px 100px 24px;

  @include p-size-tablet {
    width: 100%;
    padding: 16px 52px 24px 24px;
  }
}

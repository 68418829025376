@import '@popsure/dirty-swan/dist/colors';

.container {
  position: fixed;
  z-index: 999;
  opacity: 0.9;
  box-shadow: 0 0 8px $ds-grey-300;
  bottom: 0;
  left: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.selector {
  cursor: pointer;
  font-size: 16px;
  color: $ds-grey-500;

  &:hover {
    color: $ds-primary-300;
  }

  &Toggled {
    color: $ds-primary-500;
  }
}

.spacer {
  margin: 0 4px;
  color: $ds-grey-400;
}

.toggleButton {
  color: $ds-grey-500;

  &:hover {
    color: $ds-grey-700;
  }
}

@import '@popsure/dirty-swan/dist/grid';

.container {
  min-height: calc(100vh - 154px);
}

.content {
  column-gap: 40px;
  row-gap: 24px;

  @media screen and (max-width: 1050px) {
    flex-direction: column-reverse;
  }
}

.textContent {
  max-width: 488px;
}

.imageContent {
  gap: 16px;
  max-width: 495px;
}

.icon {
  border-radius: 16px;
  width: 112px;

  @media screen and (max-width: 1050px) {
    border-radius: 8px;
    width: 72px;
  }
}

.transparent {
  opacity: 0.2;
}

.hiddenOnMobile {
  @media screen and (max-width: 1050px) {
    display: none;
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.card {
  padding: 56px;
  flex-direction: column;

  @include p-size-mobile {
    padding: 32px;
  }

  @include p-size-desktop {
    flex-direction: row;
  }
}

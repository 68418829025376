.container {
  display: flex;
  flex-direction: column;

  text-decoration: none;

  width: fit-content;
}

.inner-container {
  display: flex;
  align-items: center;
}

.logo-big {
  width: 88px;
}

.logo-small {
  width: 72px;
}

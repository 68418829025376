@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.overlay {
  position: fixed;
  z-index: 100;
  overflow-y: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modalBackground {
  position: absolute;
  background: $ds-grey-100;
  width: calc(100vw - 32px);
  height: calc(100vh - 32px);
  left: 16px;
  top: 16px;
}

.closeButton {
  z-index: 300;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  background: transparent;
  transition: color 0.3s ease-out;
  color: $ds-grey-500;

  &:hover {
    color: $ds-grey-900;
  }
}

.tallyEmbed {
  padding: 80px;

  @include p-size-mobile {
    padding: 80px 32px 32px;
  }
}

.moveIn {
  animation-name: appear-in;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

.moveOut {
  animation-name: disappear-out;
  animation-duration: 0.4s;
  animation-delay: 0s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  visibility: hidden;
}

.fadeIn {
  animation: fade-in 0.3s both;
}

.fadeOut {
  animation-delay: 0.1s;
  animation: fade-out 0.3s both;
}

@keyframes fade-in {
  0% {
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible;
  }
}

@keyframes fade-out {
  from {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible;
  }
  to {
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
  }
}

@keyframes appear-in {
  0% {
    transform: translateY(24px);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes disappear-out {
  0% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateY(24px);
    opacity: 0;
    visibility: hidden;
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: $ds-grey-100;
  }

  @include p-size-mobile {
    align-items: flex-start;
  }
}

.leftColumn {
  min-width: 140px;

  @include p-size-mobile {
    display: flex;
    flex-direction: column;
  }
}

.paymentDate {
  margin-top: 2px;
}

.bottomBorder {
  border-bottom: 1px solid $ds-grey-200;
}

.brandIcon {
  height: 24px;
  width: 36px;

  border-radius: 2px;
}

.hideOnMobile {
  @include p-size-mobile {
    display: none;
  }
}

.showOnMobile {
  display: none;

  @include p-size-mobile {
    display: block;
  }
}

.rightColumn {
  display: flex;
  align-items: center;

  @include p-size-mobile {
    flex-direction: column-reverse;
    align-items: end;
    justify-content: start;
  }
}

.noMarginMobile {
  @include p-size-mobile {
    margin: 0;
  }
}

.topMarginMobile {
  @include p-size-mobile {
    margin-top: 8px;
  }
}

.minHeightMobile {
  @include p-size-mobile {
    min-height: 66px;
  }
}

.receiptButton {
  background-color: transparent;
}

.receiptSpinner {
  width: 20px;
  height: 20px;
}

.paypalEmail {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 64px;
}

.fixedWidthAmount {
  min-width: 80px;
  text-align: right;
}

@import '@popsure/dirty-swan/dist/grid';

.container {
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-left: -16px;
}

.card-height {
  height: 268px;
}

.info-description {
  height: 96px;

  @include p-size-mobile {
    height: fit-content;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  padding: 0 16px;

  margin-bottom: 96px;
}

.claims-button {
  border: dashed 1px $ds-purple-500 !important;
  border-radius: 8px;

  color: $ds-purple-500;
  background-color: transparent;

  height: 104px;

  cursor: pointer;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.claims-button:hover {
  background-color: $ds-purple-100;
}

.claims-button-text {
  @include p-size-mobile {
    font-size: 12px !important;
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include p-size-mobile {
    margin-top: 80px !important;
  }
}

.empty-state-icon {
  margin-left: 32px;
}

.empty-state-link {
  border: none;
  cursor: pointer;
  text-decoration: none;

  @include p-size-mobile {
    width: 100%;
  }
}

.empty-state-link:hover {
  background-color: $ds-purple-100;
}

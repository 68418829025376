@import '~@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.buttonContainer {
  column-count: 2;
  column-gap: 8px;

  button,
  a {
    flex: 1;
  }

  a:last-child {
    background-color: $ds-primary-50;
  }

  @include p-size-mobile {
    flex-direction: column;
    row-gap: 8px;
  }
}

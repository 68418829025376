@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include appear-in;
}

.chipContainer {
  gap: 16px;
  width: 100%;

  @include p-size-mobile {
    margin-top: 16px;
    flex-direction: column;
  }
}

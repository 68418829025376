@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.cardContainer {
  padding: 0;
  flex-direction: column;

  background-color: $ds-white;
  text-decoration: none;

  @include p-size-mobile {
    align-items: center;
    flex-direction: row;
  }

  @include p-size-tablet {
    background-color: $ds-grey-100;
    padding: 24px;
  }
}

.title {
  @include p-size-tablet {
    margin-bottom: 8px;
  }
}

.cardImage {
  width: 80px;
  height: 80px;

  @include p-size-tablet {
    width: 72px;
    height: 72px;
  }
}

.markdown {
  p + p,
  ol + p,
  ul + p,
  p + ul,
  p + ol,
  li + li {
    margin-top: 16px;
  }
  strong > em,
  em > strong {
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include question-container;
}

.content-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 160px;
}

.modal {
  padding: 0 24px 24px 24px;

  @include p-size-mobile {
    margin-top: 0px;
    padding: 0 16px 16px 16px;
  }
}

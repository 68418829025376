@import '@popsure/dirty-swan/dist/colors';

.dropdownButton {
  position: relative;
  height: 48px;
  color: $ds-grey-500;
  background-color: transparent;
  letter-spacing: 0.5px;

  &:hover {
    color: $ds-purple-500;
  }
}

.condensedButton {
  font-weight: 700;
  width: 40px;
}

.currentInlineButton {
  color: $ds-purple-500;
  cursor: pointer;
}

.inlineButton {
  color: $ds-grey-500;
  cursor: pointer;
}

.separator {
  color: $ds-grey-500;
  margin-left: 4px;
  margin-right: 4px;
}

.normalButton {
  font-weight: initial;
  width: 152px;
  padding: 12px 16px;
}

.dropdownContainer {
  position: absolute;
  top: 60px;
  right: -20px;

  background-color: white;
  border-radius: 8px;
  padding: 6px 0;

  z-index: 1000;
  box-shadow: 0px 4px 36px rgba(196, 196, 196, 0.24);

  button {
    display: block;
    height: 48px;
    width: 136px;
    padding: 12px 16px;

    background-color: transparent;
    color: $ds-grey-900;
    letter-spacing: 0.5px;
    text-align: left;

    cursor: pointer;

    &:hover {
      background-color: $ds-purple-100;
    }
  }

  .currentLocale {
    color: $ds-purple-500;
  }
}

@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
}

.innerContainer {
  display: flex;
  flex-direction: column;

  align-items: center;

  width: 288px;

  background-color: white;

  padding: 24px 24px 32px 24px;

  border: 2px solid $ds-grey-200;
  border-radius: 16px;
}

.button {
  width: 100%;
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';

.container {
  min-height: calc(100vh - 154px);
  @include fade-in;
}

.buttonContainer {
  width: max-content;

  @include p-size-mobile {
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
    }
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  background-color: white;
}

.section-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kira-default-icon {
  border-radius: 50%;
  border: 3px solid $ds-primary-500;
  background-color: white;
}

.header {
  @include p-size-mobile {
    text-align: center;
  }
}

.chip-container {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  justify-content: space-evenly;

  @include p-size-mobile {
    margin-top: 16px;

    padding: 0;

    & > div {
      width: 100%;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}

.coverage-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  margin-left: -16px;
}

.additional-info {
  background-color: $ds-blue-100;
  border-radius: 16px;

  display: flex;
  align-items: flex-start;

  padding: 16px;
}

.section-middle {
  padding-bottom: 80px;
}

.policy-statement-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-covered-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-left: -16px;
}

.info-description {
  height: 72px;

  @include p-size-mobile {
    height: fit-content;
  }
}

.section-bottom {
  background-color: #f7f7fc;

  padding: 40px 0;
}

.section-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-icon {
  width: 64px;
  height: 64px;

  margin: 0 auto;
}

.statement-links-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  height: 32px;
  width: 1px;
  background-color: $ds-grey-500;
}

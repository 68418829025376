@import "@popsure/dirty-swan/dist/colors";

.button {
  box-shadow: 0 0 0 1px $ds-grey-400;

  &:hover {
    box-shadow: 0 0 0 2px $ds-primary-500;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px $ds-primary-500;
  }
}

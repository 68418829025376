@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include fade-in;
}

.subText {
  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 5s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

@import '@popsure/dirty-swan/dist/grid';

.calculatorContainer {
  box-shadow: 0px 4px 6px rgba(97, 96, 162, 0.01);

  @include p-size-tablet {
    margin-top: 32px;
  }
}

.calculatorFormContainer,
.inputContainer {
  @include p-size-desktop {
    flex-direction: row !important;
  }
}

.ageInput {
  @include p-size-desktop {
    width: 50%;
  }
}

.oneTimePaymentInput {
  @include p-size-desktop {
    width: 50%;
  }
}

.ageInput label,
.oneTimePaymentInput label {
  font-weight: 700;
}

.resultContainer {
  width: 240px;
  margin: 0 auto;

  @include p-size-desktop {
    width: 328px;
  }
}

.infoBox {
  padding: 20px;
}

.infoIcon {
  margin: 4px 4px 0 0;
}

.signUpLink {
  @include p-size-desktop {
    margin-top: 40px;
  }
}

.iconButton {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.feesTable {
  @include p-size-tablet {
    padding: 32px 24px;
  }
}

.showTablet {
  display: none;
  @include p-size-tablet {
    display: inline;
  }
}

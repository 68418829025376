@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  padding: 0 16px;
}

.section-container {
  display: flex;
  flex-wrap: wrap;
}

.status-container {
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding: 32px 24px;
  margin-right: 16px;
  height: fit-content;

  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;

  @include p-size-mobile {
    margin-right: 0;
  }
}

.documents-container {
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding: 16px;
  height: fit-content;

  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;

  @include p-size-mobile {
    margin-bottom: 96px;
    width: 100%;
  }

  @include p-size-tablet {
    width: 288px;
  }
}

.document {
  display: flex;
  justify-content: space-between;

  padding: 16px;

  border: 1px solid $ds-grey-400;
  border-radius: 8px;

  cursor: pointer;
  text-decoration: none;
}

.document:first-child {
  margin-top: 0;
}

.document:hover {
  border-color: $ds-purple-500;
  background-color: $ds-purple-100;
  transition: 0.2s ease-in;

  &::after {
    @keyframes appearInAnimation {
      from {
        opacity: 0;
        transform: translateY(-5px);
      }
      to {
        opacity: 1;
      }
    }

    content: '';

    height: 24px;
    width: 24px;

    background-image: url('./icons/download.svg');

    animation-name: appearInAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }
}

.document-name {
  margin-left: 8px;
}

@keyframes appear-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

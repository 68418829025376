@import '@popsure/dirty-swan/dist/grid';

.row {
  @include p-size-tablet {
    width: 364px;
  }

  @include p-size-mobile {
    max-width: 364px;
  }
}

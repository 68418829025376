@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.titleContainer {
  @include p-size-mobile {
    align-items: center;
  }
}

.cardGrid {
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
}

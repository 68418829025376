@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';


.actionsWrapper {
  flex-direction: column;
  gap: 0;
  
  @include p-size-desktop {
    flex-direction: row;
    gap: 32px;
  }
}

.actionsColumn {
  flex-basis: 50%;

  @include p-size-mobile {
    flex-basis: 100%;
  }
}

.widgetWrapper {
  min-height: 75vh;
}

.widget {
  width: 592px;
  max-width: 100vw;
  height: 75vh;

  @include p-size-tablet {
    padding-bottom: 24px;
  }
}

.settingsCard {
  outline: 0;
  
  &:hover {
    outline: 0;
    background-color: $ds-primary-50;
  }

  &:focus-visible {
    background-color: $ds-primary-50;
  }
}

.signOutButton {
  display: none;

  @include p-size-mobile {
    display: block;
  }
}
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include p-size-tablet {
    @include question-container;
  }

  @include p-size-mobile {
    margin-top: 40px;
  }

  @include fade-in;
}

.cardsContainer {
  @include p-size-mobile {
    justify-content: space-between;
    max-width: 288px;
  }
}

.card {
  @include p-size-mobile {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.nextButton {
  @include p-size-mobile {
    margin-bottom: 40px;
    width: 100%;
    max-width: 288px;
  }
}

.cardContainer {
  @include p-size-mobile {
    max-width: 136px;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include question-container;
}

.welcomeIcon {
  font-size: 60px;

  @include p-size-mobile {
    font-size: 32px;
  }
}

.nextButton {
  @include p-size-mobile {
    margin-bottom: 80px;
  }
}

@import '@popsure/dirty-swan/dist/colors';

.brand {
  min-width: 56px;
  width: 56px;
  height: 40px;

  background: $ds-grey-200;
  border-radius: 2px;

  img {
    width: 100%;
    height: 100%;
  }
}

@import '@popsure/dirty-swan/dist/grid';

.container {
  margin: 0 16px 0;
  width: calc(100% - 32px);

  @include p-size-tablet {
    margin: 0 auto 80px;
    width: 100%;
  }
}

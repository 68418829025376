@import '~@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.link {
  transition: background-color 0.3s;
  
  &:hover {
    background-color: $ds-primary-50;

    & .infoIcon {
      background-color: $ds-primary-500;
    }
  }

}

.img {
  width: 80px;
  height: 80px;
  overflow: hidden;

  @include p-size-mobile {
    width: 64px;
    height: 64px;
  }
}

.imgBW {
  filter: grayscale(100%);
}

.infoIcon {
  width: 24px;
  height: 24px;
  background-color: $ds-grey-400;
  mask-image: url('../../../assets/icons/info.svg');
  transition: background-color 0.3s;
}


.mobileHidden {
  display: block;
  
  @include p-size-mobile {
    display: none;
  }
}

.mobileDisplay {
  display: none;

  @include p-size-mobile {
    display: block;
  }
}

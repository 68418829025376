@import '@popsure/dirty-swan/dist/colors';

.container {
  @keyframes skeletonAppear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: skeletonAppear 0.3s ease-out 0s 1 normal both;
}

.dummyHeroBanner {
  @keyframes heroAppear {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
    }
  }

  background: $ds-grey-100;
  border-radius: 12px;
  height: 328px;
  width: 1168px;
  z-index: -1;
}

.shiftup {
  position: relative;
  top: -164px;
}

.dummyHeaderCard {
  background: #fff;
  border-radius: 12px;
  height: 286px;
}

.dummyHeaderCardIcon {
  background: $ds-grey-200;
  border-radius: 12px;
  height: 152px;
  left: 50%;
  position: absolute;
  top: -80px;
  transform: translateX(-50%);
  width: 152px;
}

.dummyHeaderCardTitle {
  background: $ds-grey-100;
  border-radius: 8px;
  height: 34px;
  margin-top: 104px;
  width: 188px;
}

.dummyHeaderCardDescription {
  background: $ds-grey-100;
  border-radius: 8px;
  height: 96px;
  width: 816px;
}

.dummySectionCard {
  background: #fff;
  border-radius: 12px;
  height: 368px;
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

$col-info: transparent;
$col-pro: #aaeacc;
$col-con: $ds-red-100;
$col-ineligible: $ds-grey-700;

.col-info {
  --borderCol: #{$col-info};
}
.col-pro {
  --borderCol: #{$col-pro};
}
.col-con {
  --borderCol: #{$col-con};
}
.col-ineligible {
  --borderCol: #{$col-ineligible};
}
.hero {
  @keyframes heroAppear {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
    }
  }

  animation: heroAppear 0.3s ease-out 0s 1 normal both;
  border-radius: 12px;
  height: 328px;
  object-fit: cover;
  max-width: 1168px;
  width: 100%;
  z-index: -1;
}

.shiftup {
  position: relative;
  top: -164px;
}

.survey {
  text-align: center;
}
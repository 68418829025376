@import '@popsure/dirty-swan/dist/grid';

.modal > div {
  width: 100%;
  max-width: 592px;
}

.container {
  padding: 24px 24px 96px;

  @include p-size-tablet {
    padding: 24px;
  }
}

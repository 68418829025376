@import '@popsure/dirty-swan/dist/grid';

.hideOnDesktop {
  display: block;

  @include p-size-desktop {
    display: none;
  }
}

.shareBox {
  position: relative;
}

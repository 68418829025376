.error-screen-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40%;
  width: 100%;
}

.error-screen-message {
  color: var(--popsure-red-500);
  font-weight: 600;
}

@import '@popsure/dirty-swan/dist/grid';

.desktop {
  @include p-size-mobile {
    display: none;
  }
  @include p-size-tablet {
    display: none;
  }
  @include p-size-desktop {
    display: block;
  }
}

.mobile {
  @include p-size-mobile {
    display: block;
  }
  @include p-size-tablet {
    display: block;
  }
  @include p-size-desktop {
    display: none;
  }
}

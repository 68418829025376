@import '~@popsure/dirty-swan/dist/grid';
@import '~@popsure/dirty-swan/dist/colors';
@import 'mixins';

.container {
  @include p-size-tablet {
    @include question-container;
  }

  @include p-size-mobile {
    margin: 80px 0;
  }

  @include fade-in;
}

.secondaryButton {
  margin: 16px 0 0 0;
  background-color: white;

  @include p-size-tablet {
    margin: 0 0 0 16px;
  }

  &:hover {
    background-color: white;
  }

  &:before {
    border: 2px solid transparentize($ds-primary-500, 0.5);
    border-left-color: $ds-primary-500;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  min-height: calc(100vh - 160px);

  @include fade-in;
  @include p-size-mobile {
    min-height: calc(100vh - 90px);
  }
}

.grid {
  grid-template-columns: repeat(auto-fit, minmax(min(288px, 100%), 1fr));
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

.image {
  width: 96px;
  height: 96px;
}

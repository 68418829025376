@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.headerUnderline {
  height: 4px;
  background-color: $ds-primary-500;
}

.headerUnderlineGrey {
  background-color: $ds-grey-500;
}

.centeredOnMobile {
  @include p-size-mobile {
    align-items: center;
  }
}

@import '@popsure/dirty-swan/dist/grid';

.container {
  min-height: calc(100vh - 154px);
  padding: 24px 0;
}

.content {
  flex-direction: column;
  
  @include p-size-desktop {
    flex-direction: row;
  }
}

.cardIcon {
  max-width: 72px;
}

.cardTitle {
  margin-bottom: 4px;
}
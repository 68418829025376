@import '@popsure/dirty-swan/dist/colors';

.ulist {
  list-style-type: disc;
  list-style-position: outside;
}

.olist {
  list-style-type: decimal;
  list-style-position: outside;
}

.table {
  text-align: center;
}

.table td,
.table th {
  padding: 16px 8px;
}

.table th {
  font-weight: 700;
}

.table tr {
  border-bottom: 1px solid $ds-grey-200;
}

@import '@popsure/dirty-swan/dist/colors';

@import '@popsure/dirty-swan/dist/grid';

.coverageHeader {
  display: flex;
  gap: 4px;

  @include p-size-tablet {
    align-items: start;
  }
}

.headerUnderline {
  height: 4px;
  background-color: $ds-primary-500;

  @include p-size-tablet {
    width: 212px;
  }
}

.cardContent {
  @include p-size-desktop {
    padding-right: 136px;
  }

  p a {
    font-weight: 700;
  }
}

.radioContainer {
  div {
    width: 100%;

    @include p-size-tablet {
      width: 30%;
    }
  }

  @include p-size-tablet {
    flex-direction: row !important;
  }
}

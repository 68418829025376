@import '@popsure/dirty-swan/dist/grid';

.title {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &Visible {
    opacity: 1;
  }
}

.modalContent {
  min-width: 592x;
  max-width: 100%;
}

.modalFooter {
  flex-direction: column;
  
  @include p-size-desktop {
    flex-direction: row;
  }
}

.icon {
  width: 96px;
  min-height: 96px;
}
@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';

.card {
  @include p-size-mobile {
    flex-direction: column;

    text-align: center;
  }
}

.icon {
  width: 48px;
  height: 48px;

  @include p-size-mobile {
    margin:auto;
    margin-bottom: 16px;
  }
}

.description {
  @include p-size-mobile {
   margin-top: 8px;
  }
}
@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-tablet {
    min-width: 360px;
  }

  @media(max-width: 700px) {
    padding: 24px;
  }
}
@import '@popsure/dirty-swan/dist/grid';

.wrapper {
  margin-top: 48px;
  
  @include p-size-desktop {
    margin-top: 80px;
  }
}

.emailText {
  margin: 4px 0;
}

.signOutButton {
  @include p-size-mobile {
    display: none;
  }
}

.svnrModalButton {
  padding: 0;
  height: initial;
}

@import '@popsure/dirty-swan/dist/colors';

.button {
  padding: 0 !important;
  height: auto;

  &:hover {
    background-color: transparent;
    color: $ds-primary-700;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.fixedPriceBar {
  position: fixed;
  height: 96px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  border-top: 1px solid $ds-primary-100;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.mobileSticky {
  display: flex;

  @media screen and (min-width: $p-tablet-breakpoint) {
    display: none;
  }
}

.desktopSticky {
  display: none;

  @media screen and (min-width: $p-tablet-breakpoint) {
    display: flex;
  }
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';
@import 'variables';

.container {
  @include full-min-height;
}

.animatedContainer {
  @include fade-in;
}

.cardContainer {
  div + div {
    margin-top: 16px;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 24px;
}

.timeline-ellipse {
  background-color: $ds-grey-200;
  border: 1px solid $ds-grey-400;
  border-radius: 50%;

  width: 24px;
  height: 24px;
}

.timeline-ellipse-filled {
  background-color: $ds-purple-500;
  border: none;
}

.timeline-ellipse-filled-error {
  background-color: $ds-red-300;
  border: none;
}

.timeline-bar {
  width: 3px;
  height: 88px;

  background-color: $ds-grey-400;

  @include p-size-mobile {
    height: 120px;
  }
}

.timeline-bar-filled {
  background-color: $ds-purple-500;
}

.timeline-bar-filled-error {
  background-color: $ds-red-300;
}

.timeline-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

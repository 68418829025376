@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  height: 24px;
  display: flex;
  align-items: center;

  margin-top: 24px;

  & > * {
    flex: 1 1 0px;
  }

  @include p-size-mobile {
    display: block;
  }
}

.progressRoot {
  display: flex;
  align-items: center;
}

.progressContainer {
  height: 8px;
  width: 100%;
  border-radius: 4px;
  background-color: $ds-primary-100;
  overflow: hidden;
  margin-right: 16px;

  transition: background-color 0.3s;

  &--warning {
    @extend .progressContainer;
    background-color: $ds-yellow-500;
  }
}

.progress {
  height: 100%;
  border-radius: 4px;
  background-color: $ds-primary-500;

  transition: width 0.3s, background-color 0.3s;

  &--warning {
    @extend .progress;
    background-color: $ds-yellow-500;
  }
}

@keyframes spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  $size: 20px;

  width: $size;
  height: $size;

  border-radius: 50%;
  border: 3px solid $ds-primary-100;
  border-left-color: $ds-primary-500;

  animation: spinner-rotate 0.9s infinite;

  flex-shrink: 0;
}

.spacer {
  $size: 20px;

  width: $size;
  height: $size;
}

.stroke-primary-500 {
  stroke: $ds-primary-500;
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.tableContainer {
  max-width: 440px;
  background-color: $ds-grey-100;

  @include p-size-mobile {
    padding: 16px;
  }
  
  tr {
    border-bottom: 1px solid $ds-grey-300;
  }
}

.tableHeader {
  padding-bottom: 12px;
  
}


@import '@popsure/dirty-swan/dist/colors';

.divider {
  width: 100%;
  height: 2px;
  background: $ds-grey-200;
  display: block;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
  line-height: initial;
  padding: 0;
  border-radius: 4px;
  color: $ds-grey-400;

  &:focus-visible, &:hover {
    color: $ds-primary-500;
  }
}

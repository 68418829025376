@import '~@popsure/dirty-swan/dist/grid';

.container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.hideOnDesktop {
  display: block;
  
  @include p-size-desktop {
    display: none;
  }
}

.buttonContainer {
  button {
    @include p-size-tablet {
      width: initial;
      min-width: 212px;
    }
  }

  button:first-child {
    margin: 0 0 16px 0;

    @include p-size-tablet {
      margin: 0 16px 0 0;
    }
  }
}

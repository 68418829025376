@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.headerImgWrapper {
  position: relative;
  width: 100%;
  height: 72px;

  @include p-size-tablet {
    height: 132px;
    padding-top: 72px;
    margin-bottom: 40px;
  }
}

.headerImg {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 72px;
  border-radius: 8px;

  @include p-size-tablet {
    height: 112px;
    top: 72px;
  }
}

.titleContainer {
  margin-top: 64px;

  @include p-size-tablet {
    margin-top: 76px;
  }
}

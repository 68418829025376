@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-mobile {
    padding: 32px 24px;
  }
}

.contentContainer {
  @include p-size-mobile {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.pricingContainer {
  height: 100px;
  @include p-size-mobile {
    margin: 0;
  }

  &--disabled {
    opacity: 0.3;
  }
}

.pricingBox {
  height: 100px;
  justify-content: space-around;
  flex-grow: 1;
}

.buttonDisabled {
  opacity: 0.3;
  pointer-events: none;
}

.errorButton {
  background-color: transparent;
  border: none;
}

.editButton {
  line-height: 0;
}

.sliderContainer {
  @include p-size-mobile {
    margin-top: 40px;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  padding: 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

@keyframes appear-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.icon {
  width: 104px;
  height: 104px;
}

.button {
  @include p-size-mobile {
    margin-bottom: 80px;
  }
}

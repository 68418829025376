@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include p-size-tablet {
    @include question-container;
  }

  @include p-size-mobile {
    margin: 80px 0;
  }

  @include fade-in;
}

.emailInput {
  max-width: 100%;
  width: 464px;
}

.buttonContainer {
  flex-direction: column;

  @include p-size-tablet {
    flex-direction: row;
  }
}

.label {
  @include p-size-mobile {
    margin-bottom: 16px;
  }
}
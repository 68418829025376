@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/font-weight';

.container {
  padding: 24px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(97, 96, 162, 0.01);
}

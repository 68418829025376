@import '@popsure/dirty-swan/dist/colors';
@import 'mixins';

@keyframes placeholderGlow {
  from {
    background-position: -40px 0, 100%;
  }
  to {
    background-position: 40px 0, 100%;
  }
}

.container {
  align-items: center;
  border-radius: 8px;
  border: 1px solid $ds-primary-500;
  padding: 16px 12px;

  @include appear-in;
}

.placeholder {
  width: 34px;
  height: 42px;
  border-radius: 4px;
  background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.25),
        rgba(255, 255, 255, 0)
      )
      no-repeat,
    linear-gradient($ds-primary-100, $ds-primary-100);
  background-size: 32px 100%, 100%;
  animation: placeholderGlow 2s linear infinite;
}

.spinner {
  position: relative;
  margin-left: auto;
  width: 48px;
  color: $ds-primary-500;
}

@import '@popsure/dirty-swan/dist/colors';

// ------------------------------------- Regular -------------------------------------
.table {
  text-align: left;
  vertical-align: top;
  table-layout: fixed;

  th,
  td {
    padding: 32px 16px;
  }

  tr {
    border-bottom: 1px solid $ds-grey-200;
  }

  tr:last-child {
    border-bottom: none;
  }

  ul,
  ol {
    list-style-position: inside;
  }

  // Adjust indentation and padding to have 2nd+ lines correctly indented
  li {
    list-style-type: disc;
    text-indent: -22px;
    padding-left: 24px;
  }
}

// ------------------------------------- Tabbed -------------------------------------

.scrollContainer {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  width: 100%;

  // Disable the scrollbar in all browsers
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

.tabbedTable {
  @extend .table;

  td {
    font-size: 16px !important;
  }

  td:first-child {
    left: 0;
    position: sticky;
    background: #fff;
  }
}

// ------------------------------------- Shared -------------------------------------

.smallText {
  td {
    font-size: 12px !important;
  }

  // Adjust indentation and padding to have 2nd+ lines correctly indented for small text
  li {
    list-style-type: disc;
    text-indent: -18px;
    padding-left: 20px;
  }
}

.smallPadding {
  td {
    padding: 16px;
  }
}

.noSeparator {
  border-bottom: none !important;
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  @include p-size-mobile {
    padding: 32px 24px;
  }
}

.contentContainer {
  @include p-size-mobile {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include p-size-tablet {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include p-size-desktop {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.btnContainer {
  border: 2px solid #b1b0f5;
}

.radioBtn {
  height: 36px;
}

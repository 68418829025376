@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.cardWrapper {
  @include p-size-tablet {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  border: 1px solid $ds-grey-300;
}

.toggle {
  pointer-events: none;
}
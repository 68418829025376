@import '@popsure/dirty-swan/dist/grid';

.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  padding: 0 16px;
}

.inner-container {
  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

.step-container {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 575px) {
    margin-top: 16px !important;
  }
}

.step-card {
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  padding: 24px;
  margin-right: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 176px;

  @media screen and (max-width: 575px) {
    max-width: unset;
    width: 100%;
    margin-right: 0;

    flex-direction: row;
    justify-content: flex-start;
  }
}

.step-card:last-child {
  margin-right: 0;
}

.step-card-img {
  width: 24px;
  height: 24px;
}

.step-card-description {
  @media screen and (max-width: 575px) {
    margin: 0 0 0 24px !important;
  }
}

.button {
  @include p-size-mobile {
    margin-bottom: 96px;
  }
}

@keyframes appear-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

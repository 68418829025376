@import '~@popsure/dirty-swan/dist/grid';
@import '~@popsure/dirty-swan/dist/colors';

.step {
  flex-flow: column;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.header {
  align-items: flex-start;
}

.title-spacing {
  padding-top: 4px;
}

.number-spacing {
  padding-left: 32px;

  @include p-size-tablet {
    padding-left: 40px;
  }
}

.number {
  font-size: 28px;
  width: 24px;
  line-height: 24px;
  stroke: $ds-grey-400;

  @include p-size-tablet {
    justify-content: center;
  }

  &--active {
    stroke: $ds-primary-500;
  }
}

.title {
  align-items: center;
  margin-left: 8px;

  @include p-size-tablet {
    margin-left: 16px;
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  background: #fff;
  border-radius: 12px;

  @include p-size-mobile {
    padding: 16px;
  }
}

.header {
  column-gap: 16px;
  flex-grow: 1;
}

.title {
  @include p-size-mobile {
    font-size: 18px;
  }
}

.icon {
  border-radius: 8px;
  height: 40px;
  width: 40px;
}

.cta {
  @include p-size-mobile {
    margin-top: 16px;
    order: 999;
    width: 100%;
  }
}

.markdown {
  a {
    transition: color 0.3s ease-in-out;
  }

  strong {
    font-weight: 700;
  }

  p + p {
    margin-top: 24px;
  }
}

@import '@popsure/dirty-swan/dist/colors';

.button {
  background: transparent;
  border: none;
  font-family: inherit;
  text-align: left;
  border-radius: 4px;

  &:focus {
    filter: brightness(0.85);
    outline: $ds-primary-500 solid 2px;
    outline-offset: 2px;
  }
}

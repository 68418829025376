@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.headerImg {
  width: 112px;
  height: auto;
}

.title {
  gap: 20px;

  @include p-size-tablet {
    margin-top: 32px;
  }
}

.considerationsContainer {
  @include p-size-tablet {
    padding-top: 104px;
  }
}

.whyFeatherContainer {
  @include p-size-tablet {
    grid-template-columns: repeat(2, 1fr);
  }
}

.whyFeatherIcon {
  width: 32px;
  height: 32px;
}

.card {
  border: 1px solid $ds-grey-300;
  justify-content: flex-start;
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.button {
  display: flex;
  flex-direction: column;

  align-items: center;

  text-decoration: none;

  color: $ds-grey-900;

  &:hover {
    color: $ds-purple-500;
  }

  &--selected {
    @extend .button;
    color: $ds-purple-500;
  }

  @include p-size-desktop {
    flex-direction: row;
  }

  &Login {
    flex-direction: row;
  }
}

.iconWrapper {
  margin-bottom: 4px;

  @include p-size-desktop {
    margin-bottom: 0;
  }

  &Login {
    margin-right: 4px;
    margin-bottom: 0;
  }

}

.icon {
  width: 20px !important;
  height: 20px !important;
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.sectionContainer {
  align-items: center;

  @include p-size-mobile {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 32px 24px;
  }
}

.icon {
  width: 90px;
  height: 90px;
  display: block;

  @include p-size-mobile {
    width: 72px;
    height: 72px;
  }
}

.img {
  @include p-size-mobile {
    order: 2;
  }
}

.infoContainer {
  line-height: 16px;
  flex: 1 1 auto;

  @include p-size-mobile {
    order: 1;
    flex: 0 0 50%;
  }
}

.info {
  @include p-size-tablet {
    display: flex;
    flex-wrap: wrap;
  }

  @include p-size-mobile {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.statusContainer {
  flex-direction: column;
  height: 92px;

  @include p-size-mobile {
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-grow: 1;
    order: 3;
    flex: 0 0 100%;
  }
}

.iconWrapper {
  padding: 6px;
  width: 32px;
  height: 32px;
}

.assessmentStartedContent {
  grid-template-columns: 224px 424px;
}

@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.info-box {
  display: flex;
  height: fit-content;
  padding: 16px;

  background-color: white;
  box-shadow: 0px 2px 3px rgba(38, 38, 46, 0.04);
  border-radius: 8px;

  width: 100%;
  max-width: 424px;

  @include appear-in;

  @include p-size-mobile {
    max-width: 100%;
  }
}

.info-icon-container {
  margin-right: 16px;
}

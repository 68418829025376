@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/font-weight';

.body {
  composes: p-p from '~@popsure/dirty-swan/dist/index.css';
  max-height: 60vh;
  overflow-y: scroll;

  h1 {
    font-weight: $p-font-weight-bold;
    font-size: 18px;
    line-height: 24px;
    margin-top: 24px;
  }

  b {
    font-weight: $p-font-weight-bold;
  }

  p {
    margin-top: 16px;
  }
}

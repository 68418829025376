@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  background-color: white;
  @include fade-in;
}

.header {
  @include p-size-mobile {
    text-align: center;
  }
}

.review-badge-container {
  display: flex;
  justify-content: center;
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';
@import 'variables';

.container {
  @include fade-in;
  @include full-min-height;
}

.checkboxContainer {
  @include appear-in-with-delay;
}

.checkboxContent {
  min-height: 24px;
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

.icon{
  width: 96px;
  height: 96px;
}

.generatingDescription {
  @include appear-in-with-delay;
}
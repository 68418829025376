@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';

.container {
  min-height: calc(100vh - 154px);
  @include fade-in;
}

.innerContainer {
  flex-direction: column;
  max-width: 100%;

  @include p-size-tablet {
    flex-direction: row;
  }

  .labelContainer,
  .additionalInfo,
  button {
    @include p-size-mobile {
      width: 100%;
    }
  }
}

.additionalInfo {
  @include p-size-tablet {
    flex-direction: column;
    min-width: 30%;
  }
}

@import '~@popsure/dirty-swan/dist/grid';

.modalContainer {
  max-width: 100%;
}

.buttonWrapper {
  display: flex;
  
  @include p-size-mobile {
    display: block;
  }
}

.button {
  display: block;
  width: 50%;
  
  @include p-size-mobile {
    width: 100%;
  }
}
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  min-height: calc(100vh - 160px);

  @include fade-in;
  @include p-size-mobile {
    min-height: calc(100vh - 90px);
  }
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.imageWrapper {
  width: 64px;
  height: 64px;
  overflow: hidden;
  margin-right: 4px;

  &.bordered {
    outline: 1px solid $ds-grey-300;
  }
}

.card {
  img {
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
  }

  &:hover img {
    transform: scale(1.2);
  }

  &:focus-visible {
    outline-color: $ds-primary-500;
  }
}

.cardTitle {
  margin-bottom: -8px;
}

.innerCard {
  padding: 8px;
}
@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include p-size-tablet {
    @include question-container;
  }

  @include p-size-mobile {
    margin: 80px 0;
  }

  @include fade-in;
}

.buttons-container {
  & > button:not(:first-child) {
    margin-left: 8px;

    @include p-size-mobile {
      margin-left: 0;
    }
  }
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import 'mixins';

.container {
  align-items: center;
  border-radius: 8px;
  border: 1px solid $ds-primary-500;
  padding: 16px 12px;

  @include appear-in;
}

.name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 60%;
}

.button {
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;

  &:hover,
  &:focus {
    background: $ds-primary-50;
  }
}

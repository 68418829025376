@import '@popsure/dirty-swan/dist/grid';

@keyframes fadeIn {
  from {
    filter: saturate(0);
    opacity: 0.5;
  }
  to {
    filter: saturate(1);
    opacity: 1;
  }
}

.header {
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;

  @include p-size-mobile {
    flex-direction: column;
    align-items: start;
    row-gap: 8px;
  }
}

.card {
  margin-bottom: 24px;
  animation: fadeIn 0.2s ease-out 1 normal both;

  @include p-size-tablet {
    margin-bottom: 32px;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';
@import 'variables';

.container {
  @include full-min-height;
}

.contentInnerContainer {
  width: 424px;

  @include p-size-mobile {
    width: 100%;
  }
}

.button {
  @include p-size-mobile {
    max-width: 100%;
    width: 100%;
  }
}

.additionalInfo {
  flex: 1 1 auto;
}

.additionalInfoWrapper {
  @include p-size-mobile {
    flex-direction: column;
    gap: 16px;
  }
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';


.button {
  @include p-size-mobile {
    width: 100%;
  }
}

.updateInfo {
  @include p-size-mobile {
    margin-top: 24px;
  }
}

.loaderContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include fade-in;
}

.billingList {
  margin-top: 48px;

  @include p-size-mobile {
    margin-top: 32px;
  }
}

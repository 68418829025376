@import '~@popsure/dirty-swan/dist/colors';

.left {
  transform: rotate(90deg);
  left: 0;
}

.right {
  transform: rotate(-90deg);
  right: 0;
}

.arrowButton {
  position: absolute;
  width: 32px;
  height: 32px !important;

  padding: 0;

  border-radius: 50%;
  background-color: $ds-grey-200;

  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    background-color: $ds-grey-200;
  }
}

.arrowInactive {
  stroke: $ds-primary-100;

  &:hover,
  &:focus {
    cursor: not-allowed;
  }
}

.arrowActive {
  stroke: $ds-primary-500;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.animatedContainer {
  @include fade-in;
}

.container {
  max-width: 592px;
}

.cardContainer {
  div + div {
    margin-top: 16px;
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  margin-bottom: 104px;
  margin-top: 104px;

  @include p-size-mobile {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

.titleContainer {
  @include p-size-mobile {
    align-items: center;
    flex-direction: column;
  }
}

.sectionHeaderContainer {
  @include p-size-mobile {
    align-items: center;
  }
}

.tileContainer {
  grid-template-columns: repeat(auto-fit, minmax(min(282px, 100%), 1fr));
  justify-items: center;
  @include p-size-mobile {
    row-gap: 16px;
  }
}

.tile {
  max-width: 282px;
  padding: 24px 8px 0 8px;

  h4 {
    margin-bottom: 4px;
  }
}

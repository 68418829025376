@import '~@popsure/dirty-swan/dist/colors';

.row {
  justify-content: space-between;
}

.divider {
  border-top: 1px solid $ds-grey-200;
  padding-top: 24px;
}

.noClaimsContainer {
  ul {
    list-style-type: disc;
    padding-left: 24px;
  }
}

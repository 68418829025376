@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.infoDurationContainer {
  min-height: 56px;
}

.infoBeneficiariesContainer {
  border-top: 1px solid $ds-grey-200;
  padding-top: 24px;
}

.infoBeneficiariesContent {
  display: flex;
  justify-content: space-between;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @include p-size-tablet {
    grid-template-columns: 30% 70%;
  }

  span {
    hyphens: auto;
    word-break: break-word;
  }
}

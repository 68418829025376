@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include p-size-tablet {
    @include question-container;
  }

  @include p-size-mobile {
    margin: 80px 0;
  }

  @include fade-in;
}

@keyframes appear-in {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.continueBtn {
  display: table;
  visibility: hidden;
}

.continueBtnDisplay {
  display: table;
  visibility: visible;

  animation-name: appear-in;
  animation-timing-function: ease;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

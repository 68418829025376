@import '@popsure/dirty-swan/dist/colors';

.dropdown {
  display: none;
}

.dropdown--expanded {
  display: block;
  min-width: 152px;
  position: absolute;
  top: 60px;
  right: -8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  z-index: 1;
}

.dropdown-item {
  padding: 16px;
  border: none;
  display: block;

  cursor: pointer;
  background: none;

  text-align: center;
}

.dropdown-item--danger {
  color: $ds-red-500;
}

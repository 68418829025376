@import '@popsure/dirty-swan/dist/grid';

.wrapper {
  margin-top: -24px;
  padding-top: 24px;
  margin-bottom: -80px;
  padding-bottom: 80px;
  
  @include p-size-desktop {
    margin-top: 0;
    padding-top: 0;
  }
}

.cardGrid {
  @include p-size-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include p-size-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
}

@import '~@popsure/dirty-swan/dist/colors';

.carouselWrapper > div {
  transition-timing-function: cubic-bezier(.4,0.8,.4,1.15);
}

.progressItem {
  height: 4px;
  position: relative;
  overflow: hidden;

  &Inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: $ds-grey-700;
    transform: translate3d(-101%, 0, 0);
  }
}
@import 'mixins';

.container {
  min-height: calc(100vh - 154px);
  max-width: 624px; // = 592px + 2 * 16px padding
  @include fade-in;

  p + p {
    margin-top: 24px;
  }
}

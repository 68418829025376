@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.linkCell {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  text-decoration: none;
  border: 1px solid $ds-grey-400;
  border-radius: 8px;

  &:hover {
    border: 1px solid $ds-purple-500;
    transition: 0.2s ease-in;
  }

  &:focus {
    outline: 2px solid $ds-purple-500;
  }
}

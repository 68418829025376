@import '~@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  @include p-size-tablet {
    @include question-container;
  }

  @include p-size-mobile {
    margin: 80px 0;
  }

  @include fade-in;
}

.label {
  min-height: 24px;
}

.button {
  @include p-size-mobile {
    margin-bottom: 80px;
    width: 100%;
  }
}

@import '~@popsure/dirty-swan/dist/grid';

.wrapper {
  margin-top: 48px;

  @include p-size-desktop {
    margin-top: 80px;
  }
}

.mobileHidden {
  display: inline-flex;
  
  @include p-size-mobile {
    display: none;
  }
}

.mobileDisplay {
  display: none;

  @include p-size-mobile {
    display: block;
  }
}

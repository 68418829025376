@import '@popsure/dirty-swan/dist/grid';

.root {
  margin-bottom: 80px;
  // Target the root div's content and give it some "safety padding" on mobile to be able to access buttons
  // despite a bug in Firefox mobile (https://bugzilla.mozilla.org/show_bug.cgi?id=1760924)
  @include p-size-mobile {
    & > [class*='style_container'] {
      padding-bottom: 160px;
    }
  }
}

.mobileApp * {
  user-select: none;

  input {
    user-select: text;
  }
}

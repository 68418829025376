@import '~@popsure/dirty-swan/dist/grid';

.container {
  display: flex;
  flex-wrap: wrap;

  @include p-size-mobile {
    justify-content: center;
  }
}

.review-rating {
  margin-bottom: 24px;

  @include p-size-tablet {
    margin-bottom: 0;
    margin-right: 32px;
  }
}

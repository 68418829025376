@import '@popsure/dirty-swan/dist/colors';

.line-group {
  padding: 0 16px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
}

.line {
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  position: relative;

  align-items: center;

  &:not(:last-child) {
    border-bottom: solid 1px $ds-grey-200;
  }
}

.last-digits {
  display: block;
  margin-right: 16px;
  width: 88px;
  white-space: nowrap;
}

.expires-at {
  display: block;
  color: $ds-grey-500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  flex-grow: 1;
  padding-left: 16px;
  min-width: 0;
}

.overflow {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
}

.p-badge--inactive-grey {
  background: $ds-grey-400;
}

@import '@popsure/dirty-swan/dist/colors';

.buttonContainer {
  font-family: inherit;

  width: 100%;
  height: 56px;


  box-shadow: 0 0 0 1px $ds-grey-400;

  flex-direction: row;

  color: $ds-grey-900;

  transition: box-shadow 0.3s;
  outline: none;

  &Content {
    display: flex;
    align-items: center;
    text-align: left;
  }

  &:hover {
    box-shadow: 0 0 0 2px  $ds-primary-500;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px  $ds-primary-500;
  }

  &--no-hover:hover {
    color: $ds-grey-900;
    box-shadow: 0 0 0 1px $ds-grey-400;
    outline: none;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.title {
  font-size: 1rem;
  margin-left: 12px;
}

.right-icon {
  margin-right: 16px;
}

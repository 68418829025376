@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);
  row-gap: 20px;

  @include p-size-desktop {
   flex-direction: row;
   justify-content: space-between;
  }
}

.child-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px;
  min-height: 100%;

  @include p-size-desktop {
    margin-bottom: -80px;
    width: 50%;
  }

  @include p-size-tablet {
    padding: 64px 96px;
  }
}
@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes text-appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes text-disappear {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes spinner-appear {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.spinner {
  color: $ds-purple-500;
  animation: spinner-appear 0.6s;
  animation-fill-mode: both;
  animation-delay: 0.6s;
}

.description {
  text-align: center;

  animation: text-appear 0.3s;
  animation-fill-mode: both;
  animation-delay: 0.3s;
}

.text-appear {
  animation: text-appear 0.3s;
  animation-fill-mode: both;
}

.text-disappear {
  animation: text-disappear 0.3s;
  animation-fill-mode: both;
}

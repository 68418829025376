@import '@popsure/dirty-swan/dist/colors';

$infoColor: transparent;
$proColor: $ds-spearmint-300;
$conColor: $ds-red-100;
$ineligibleColor: $ds-grey-700;

.info {
  --borderColor: #{$infoColor};
}
.pro {
  --borderColor: #{$proColor};
}
.con {
  --borderColor: #{$conColor};
}
.ineligible {
  --borderColor: #{$ineligibleColor};
}

.container {
  background: $ds-grey-100;
  border-left: 4px solid var(--borderColor);
  border-radius: 8px;
}

.left,
.right {
  grid-column: 1 / 3;
}

@media screen and (min-width: 768px) {
  .left {
    grid-column: 1 / 2;
  }

  .right {
    grid-column: 2 / 3;
  }
}

.chevron {
  transition: filter 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.chevronClosed {
  transform: rotate(180deg);
}

.header {
  background: transparent;
  border: none;
  column-gap: 16px;
  cursor: pointer;
  font-family: inherit;
  outline: none;
  position: relative;
  text-align: inherit;

  // To increase the clickable header area without having to add padding to the header
  &::after {
    box-sizing: content-box;
    content: ' ';
    height: 100%;
    left: -24px;
    padding: 24px;
    position: absolute;
    top: -24px;
    width: 100%;
  }
}

// Remove the bottom padding from clickable header area
.headerOpen {
  &::after {
    padding: 24px 24px 0 24px;
  }
}

.header:hover {
  .chevron {
    filter: brightness(0.425);
  }
}

.markdown {
  margin-top: 14px;

  a {
    transition: color 0.3s ease-in-out;
  }

  strong {
    font-weight: 700;
  }
}

@import 'mixins';
@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-tablet {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include p-size-mobile {
    margin: 80px 0;
  }

  @include fade-in;
}

.hidden {
  display: none;
}

.photo {
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.spinner-container {
  width: 132px;
  height: 178px;
  border-radius: 8px;
  position: relative;

  & > div {
    transform: translateY(-50%);
  }
}

@import '@popsure/dirty-swan/dist/grid';

.icon {
  width: 80px;
  height: 80px;

  @include p-size-mobile {
    width: 64px;
    height: 64px;
  }
}

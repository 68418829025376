@import '~@popsure/dirty-swan/dist/colors';

.container {
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 24px;
  border-radius: 8px;

  label {
    flex-flow: row-reverse;
    align-items: center;

    &:before {
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin: 0 0 0 auto;
    }
  }
}

.loading {
  label {
    cursor: default;

    &:before {
      cursor: default;
      content: '';
      background-image: none !important;
      background-color: transparent !important;
      border: 4px solid $ds-grey-200 !important;
      border-top: 4px solid $ds-primary-500 !important;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  width: 105px;
}
@import '@popsure/dirty-swan/dist/grid';
@import 'mixins';

.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-container {
  @include appear-in;
}

.form-container {
  @include appear-in-with-delay;
}

.user-info-container {
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px;
}

.user-info-content {
  display: flex;
  justify-content: space-between;
}

.button {
  @include p-size-mobile {
    margin-bottom: 120px;
  }
}
